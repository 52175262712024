import React from "react";
import Moment from "moment";
import UpdateHospital from "./UpdateHospital";
import PDFViewer from "../components/PDFViewer";
import {BsFillTrashFill} from "react-icons/bs"

class Hospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.GetHospitalInfo = this.GetHospitalInfo.bind(this);
  }

  async GetHospitalInfo() {
    this.props.GetHospitals();
  }

  render() {
    return (
      <tr style={{ textAlign: "center" }}>
        <td>
          <BsFillTrashFill style={{color: "black", cursor: "pointer"}} onClick={() => this.props.RemoveHospital(this.props.id)}/>
        </td>
        <td>
          <p>
          <UpdateHospital
              HospitalID={this.props.id}
              HospitalName={this.props.name}
              GetHospitalInfo={this.GetHospitalInfo}
            />
          </p>
        </td>
        <td>
          {this.props.file ? <p><PDFViewer type="hospital_file" size={30} file={this.props.file}/></p> : <p>لا يوجد مرفق</p>}
        </td>
        <td>
          <p>
            {this.props.CreateDate === null
              ? "مجهول"
              : Moment(this.props.CreateDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>{this.props.name === null ? "مجهول" : this.props.name}</p>
        </td>
        <td>
          <p>{this.props.id === null ? "مجهول" : this.props.id}</p>
        </td>
      </tr>
    );
  }
}

export default Hospital;

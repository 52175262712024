import axios from "axios";

// const url = "http://localhost:5000"
const url = "https://f5dy4kqoc3.execute-api.us-east-1.amazonaws.com/prod/";

const Axios = axios.create({
  baseURL: url,
});

export default Axios;

const PrivateNavBar = ({ children }) => {
  const auth = window.localStorage.getItem("token");
  return auth ? (
    children
  ) : (
    <ul className="Navbar" style={{ height: "3.3rem" }}></ul>
  );
};

export default PrivateNavBar;

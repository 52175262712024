import React from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { Logger } from "logging-library";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
import { GrDocumentPdf } from "react-icons/gr";
import API from "../config/API";

class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      FileType: "",
      content: ""
    };

    this.ShowModal = this.ShowModal.bind(this);
    this.DeleteFile = this.DeleteFile.bind(this);
    this.CheckFileType = this.CheckFileType.bind(this);
    this.getContentFromURL = this.getContentFromURL.bind(this);
  }

  async getContentFromURL(url) {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const content = await response.text();
      this.CheckFileType(content);
      this.setState({ content: content })

      return content;
    } catch (error) {
      console.error('Error fetching content:', error);
      return null;
    }
  }

  async DeleteFile() {
    const data = {
      FileID: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/delete_file", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.props.GetCaseFiles();
          this.ShowModal();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  ShowModal() {
    this.setState({ show: !this.state.show });
  }

  CheckFileType(content) {
    const startIndex = content.indexOf("data:");
    if (startIndex === -1) {
      return null;
    }

    const endIndex = content.indexOf(";base64");
    if (endIndex === -1) {
      return null;
    }

    const mimeType = content.substring(startIndex + "data:".length, endIndex);
    const extensions = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/pdf': 'pdf',
      'application/msword': 'doc',
    };

    this.setState({ FileType: extensions[mimeType] || null });
  }

  componentDidMount() {
    if (this.props.file) {
      this.getContentFromURL(this.props.file);
    }
  }

  render() {
    return (
      <>
        <div onClick={this.ShowModal}>
          <GrDocumentPdf size={this.props.size} />
          <p style={{ textAlign: "right" }}>{this.props.FileName}</p>
        </div>
        <Modal fullscreen={true} show={this.state.show} onHide={this.ShowModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ margin: "auto" }}>
              {this.props.FileName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ width: "100%", margin: "0 auto" }}>
              <FileViewer
                fileType={this.state.FileType}
                filePath={this.state.content}
                errorComponent={CustomErrorComponent}
                onError={(e) => {
                  Logger.logError(e, "error in file-viewer");
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" disabled>
              {this.props.Category}
            </Button>
            {this.props.type === "hospital_file" ? null : (
              <Button variant="danger" onClick={this.DeleteFile}>
                حذف
              </Button>
            )}
            <Button variant="primary" onClick={this.ShowModal}>
              <a
                href={this.props.file}
                download
                style={{ color: "white", textDecoration: "none" }}
              >
                تنزيل
              </a>
            </Button>
            <Button variant="secondary" onClick={this.ShowModal}>
              إغلاق
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PDFViewer;

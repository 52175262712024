import React from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { BiEdit } from "react-icons/bi";
import API from "../config/API";

class UpdatePass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NewPass: "",
      UpdatePasswordModal: false,
    };

    this.UpdateUserPassword = this.UpdateUserPassword.bind(this);
    this.UploadNewPassword = this.UploadNewPassword.bind(this);
    this.UpdatePasswordModal = this.UpdatePasswordModal.bind(this);
  }

  UpdatePasswordModal() {
    this.setState({ UpdatePasswordModal: !this.state.UpdatePasswordModal });
  }

  UpdateUserPassword(event) {
    this.setState({ NewPass: event.target.value });
  }

  async UploadNewPassword() {
    const data = {
      Token: window.localStorage.getItem("token"),
      UserID: this.props.UserID,
      NewPass: this.state.NewPass,
    };

    await API.post("/reset_password", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          window.alert(res.ErrorMessage);
        }
        if (res.data) {
          window.alert("تم تحديث كلمة المرور")
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  render() {
    return (
      <>
        <BiEdit className="EditButton" onClick={this.UpdatePasswordModal} />
        <Modal show={this.state.UpdatePasswordModal} onHide={this.UpdatePasswordModal}>
          <Modal.Header closeButton>
            <Modal.Title>تعديل كلمة مرور المستخدم</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ textAlign: "right" }}>
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input">كلمة المرور الجديدة</label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  onChange={this.UpdateUserPassword}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdatePasswordModal}>
              إغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadNewPassword();
                this.UpdatePasswordModal();
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UpdatePass;

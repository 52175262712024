import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import API from "../config/API";
import User from "../components/User";
import Agent from "../components/Agent";
import Hospital from "../components/Hospital"
import AdminCase from "../components/AdminCase"
import Classification from "../components/Classification";
import { Line, Bar } from 'react-chartjs-2';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, ArcElement, BarElement } from 'chart.js';
import { FaCaretSquareRight, FaCaretSquareLeft } from "react-icons/fa"

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, ArcElement, BarElement);

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      agents: [],
      hospitals: [],
      cases: [],
      Classifications: [],
      index: 0,
      NewCases: 0,
      AcceptedCases: 0,
      SystemUsers: 0,
      AllCases: 0,
      NewUserModal: false,
      NewAgentModal: false,
      NewHospitalModal: false,
      NewClassificationModal: false,
      AgentsTab: false,
      UsersTab: true,
      CasesTab: false,
      ChartsTab: false,
      HospitalsTab: false,
      ClassificationsTab: false,
      search: "",
      NewUser: {
        name: "",
        email: "",
        password: "",
        position: "الوظيفة",
      },
      NewAgent: {
        name: "",
      },
      NewHospital: {
        name: "",
        file: ""
      },
      NewClassification: {
        name: "",
      },
      CasesByStageChart: {
        labels: {},
        data: {}
      },
      CasesBasedOnSubmitDate: {
        labels: {},
        data: {}
      },
      CasesBasedOnAgent: {
        labels: {},
        data: {}
      },
      CasesBasedOnPatientsBirthdates: {
        labels: {},
        data: {}
      },
      ChartAgent: "نوال الجلاد"
    };

    // Modals
    this.NewUserModal = this.NewUserModal.bind(this);
    this.NewAgentModal = this.NewAgentModal.bind(this);
    this.NewHospitalModal = this.NewHospitalModal.bind(this);
    this.NewClassificationModal = this.NewClassificationModal.bind(this);

    // Uploading
    this.UploadNewUser = this.UploadNewUser.bind(this);
    this.UploadNewAgent = this.UploadNewAgent.bind(this);
    this.UploadNewHospital = this.UploadNewHospital.bind(this);
    this.UploadNewClassification = this.UploadNewClassification.bind(this);

    // Fetching
    this.GetNewCases = this.GetNewCases.bind(this);
    this.GetAcceptedCases = this.GetAcceptedCases.bind(this);
    this.GetSystemUsers = this.GetSystemUsers.bind(this);
    this.GetAllCases = this.GetAllCases.bind(this);
    this.GetUsers = this.GetUsers.bind(this);
    this.GetAgents = this.GetAgents.bind(this);
    this.GetHospitals = this.GetHospitals.bind(this);
    this.GetCases = this.GetCases.bind(this);
    this.GetClassifications = this.GetClassifications.bind(this);
    this.GetCasesBasedOnStage = this.GetCasesBasedOnStage.bind(this);
    this.GetCasesBasedOnStage = this.GetCasesBasedOnStage.bind(this);
    this.GetCasesBasedOnSubmitDate = this.GetCasesBasedOnSubmitDate.bind(this);
    this.GetCasesBasedOnAgent = this.GetCasesBasedOnAgent.bind(this);
    this.GetCasesBasedOnPatientsBirthdates = this.GetCasesBasedOnPatientsBirthdates.bind(this);

    // Removing
    this.RemoveCase = this.RemoveCase.bind(this);
    this.RemoveClassification = this.RemoveClassification.bind(this);
    this.RemoveHospital = this.RemoveHospital.bind(this);

    // Updating
    this.UpdateNewClassification = this.UpdateNewClassification.bind(this);
    this.UpdateNewUserName = this.UpdateNewUserName.bind(this);
    this.UpdateNewUserPassword = this.UpdateNewUserPassword.bind(this);
    this.UpdateNewUserEmail = this.UpdateNewUserEmail.bind(this);
    this.UpdateNewUserPosition = this.UpdateNewUserPosition.bind(this);
    this.UpdateNewAgent = this.UpdateNewAgent.bind(this);
    this.UpdateUserSearch = this.UpdateUserSearch.bind(this);
    this.UpdateAgentSearch = this.UpdateAgentSearch.bind(this);
    this.UpdateHospitalSearch = this.UpdateHospitalSearch.bind(this);
    this.UpdateNewHospital = this.UpdateNewHospital.bind(this);
    this.UpdateHospitaloginlFile = this.UpdateHospitalFile.bind(this);
    this.UpdateChartAgent = this.UpdateChartAgent.bind(this);
    this.UpdateCaseSearch = this.UpdateCaseSearch.bind(this);

    this.generateRandomColor = this.generateRandomColor.bind(this);

    // Tabs Switching
    this.UsersTab = this.UsersTab.bind(this);
    this.AgentsTab = this.AgentsTab.bind(this);
    this.HospitalsTab = this.HospitalsTab.bind(this);
    this.CasesTab = this.CasesTab.bind(this);
    this.ClassificationsTab = this.ClassificationsTab.bind(this);
    this.ChartsTab = this.ChartsTab.bind(this);

    this.convertToBase64 = this.convertToBase64.bind(this);
    this.increaseIndex = this.increaseIndex.bind(this);
    this.decreaseIndex = this.decreaseIndex.bind(this);
  }

  async increaseIndex() {
    await this.setState({ index: this.state.index + 20 });
    this.GetCases();
  }

  async decreaseIndex() {
    await this.setState({ index: this.state.index - 20 });
    this.GetCases();
  }

  async UpdateCaseSearch(event) {
    await this.setState({ search: event.target.value });
    this.GetCases();
  }

  generateRandomColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgb(${r}, ${g}, ${b})`;
  };

  async UpdateChartAgent(value) {
    await this.setState({ ChartAgent: value })
    this.GetCasesBasedOnAgent();
  }

  async GetCasesBasedOnSubmitDate() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/cases_based_on_submit_date", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.setState((prevState) => ({
            CasesBasedOnSubmitDate: {
              ...prevState.CasesBasedOnSubmitDate,
              labels: res.data.map(item => item.c_create_date.split('T')[0]),
              data: res.data.map(item => item.case_count)
            },
          }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetCasesBasedOnAgent() {
    const data = {
      Token: window.localStorage.getItem("token"),
      agent: this.state.ChartAgent
    };

    await API.post("/cases_based_on_agent", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.setState((prevState) => ({
            CasesBasedOnAgent: {
              ...prevState.CasesBasedOnAgent,
              labels: res.data.map(item => item.c_create_date.split('T')[0]),
              data: res.data.map(item => item.cases_count)
            },
          }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetCasesBasedOnPatientsBirthdates() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/cases_based_on_patients_birthdates", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.setState((prevState) => ({
            CasesBasedOnPatientsBirthdates: {
              ...prevState.CasesBasedOnPatientsBirthdates,
              labels: res.data.map(item => item.age_range),
              data: res.data.map(item => item.cases_count)
            },
          }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetCasesBasedOnStage() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/cases_based_on_stage", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.setState((prevState) => ({
            CasesByStageChart: {
              ...prevState.CasesByStageChart,
              labels: res.data.map(item => item.c_stage),
              data: res.data.map(item => item.case_count)
            },
          }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async RemoveHospital(id) {
    const data = {
      HospitalID: id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/remove_hospital", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          window.alert("تم حذف المستشفى بنجاح")
          this.GetHospitals();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async RemoveClassification(id) {
    const data = {
      ClassificationID: id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/remove_classification", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          window.alert("تم حذف التصنيف بنجاح")
          this.GetClassifications();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  NewClassificationModal() {
    this.setState((prevState) => ({
      NewClassificationModal: !this.state.NewClassificationModal,
      NewClassification: {
        ...prevState.NewClassification,
        name: "",
      },
    }));
  }

  async UploadNewClassification() {
    const data = {
      Token: window.localStorage.getItem("token"),
      ClassificationName: this.state.NewClassification.name
    };

    await API.post("/add_classification", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetClassifications();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetClassifications() {
    const data = { search: this.state.search };

    await API.post("/get_classifications", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          window.alert(res.ErrorMessage);
        }
        if (res.data) {
          this.setState({ Classifications: res.data });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  convertToBase64(event) {
    const selectedFile = document.getElementById("inputFile").files;

    if (selectedFile.length > 0) {
      const fileToLoad = selectedFile[0];
      const fileReader = new FileReader();
      let self = this;

      fileReader.onload = async function (fileLoadedEvent) {
        let base64 = fileLoadedEvent.target.result;
        // let fileName = event.target.files[0].name;
        self.UpdateHospitalFile(base64);
        // self.UpdateFileName(fileName);
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  async RemoveCase(id) {
    const data = {
      CaseID: id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/remove_case", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          window.alert("تم حذف الملف بنجاح")
          this.GetCases();
          this.GetAcceptedCases();
          this.GetAllCases();
          this.GetNewCases();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetCases() {
    const data = {
      Token: window.localStorage.getItem("token"),
      search: this.state.search,
      index: this.state.index
    };

    await API.post("/get_cases_for_admin", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ cases: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  UpdateHospitalFile(value) {
    this.setState((prevState) => ({
      NewHospital: {
        ...prevState.NewFile,
        name: this.state.NewHospital.name,
        file: value,
      },
    }));
  }

  HospitalsTab() {
    this.setState({
      HospitalsTab: true,
      UsersTab: false,
      AgentsTab: false,
      CasesTab: false,
      ClassificationsTab: false,
      ChartsTab: false,
      search: ""
    })
  }

  UsersTab() {
    this.setState({
      UsersTab: true,
      AgentsTab: false,
      HospitalsTab: false,
      CasesTab: false,
      ClassificationsTab: false,
      ChartsTab: false,
      search: ""
    });
  }

  CasesTab() {
    this.setState({
      UsersTab: false,
      AgentsTab: false,
      HospitalsTab: false,
      CasesTab: true,
      ClassificationsTab: false,
      ChartsTab: false,
      search: ""
    });
  }

  AgentsTab() {
    this.setState({
      AgentsTab: true,
      UsersTab: false,
      HospitalsTab: false,
      CasesTab: false,
      ClassificationsTab: false,
      ChartsTab: false,
      search: ""
    });
  }

  ClassificationsTab() {
    this.setState({
      ClassificationsTab: true,
      AgentsTab: false,
      UsersTab: false,
      HospitalsTab: false,
      CasesTab: false,
      ChartsTab: false,
      search: ""
    });
  }

  ChartsTab() {
    this.setState({
      ClassificationsTab: false,
      AgentsTab: false,
      UsersTab: false,
      HospitalsTab: false,
      CasesTab: false,
      ChartsTab: true,
      search: ""
    });
  }

  NewAgentModal() {
    this.setState((prevState) => ({
      NewAgentModal: !this.state.NewAgentModal,
      NewAgent: {
        ...prevState.NewAgent,
        name: "",
      },
    }));
  }

  UpdateNewHospital(event) {
    this.setState((prevState) => ({
      NewHospital: {
        ...prevState.NewHospital,
        name: event.target.value,
      },
    }));
  }

  UpdateNewAgent(event) {
    this.setState((prevState) => ({
      NewAgent: {
        ...prevState.NewAgent,
        name: event.target.value,
      },
    }));
  }

  NewHospitalModal() {
    this.setState((prevState) => ({
      NewHospitalModal: !this.state.NewHospitalModal,
      NewHospital: {
        ...prevState.NewHospital,
        name: "",
      },
    }));
  }

  async UploadNewHospital() {
    const data = {
      Token: window.localStorage.getItem("token"),
      HospitalName: this.state.NewHospital.name,
      HospitalAgreement: this.state.NewHospital.file
    };

    await API.post("/add_hospital", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetHospitals();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadNewAgent() {
    const data = {
      Token: window.localStorage.getItem("token"),
      AgentName: this.state.NewAgent.name,
    };

    await API.post("/add_agent", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetAgents();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetAgents() {
    const data = {
      Token: window.localStorage.getItem("token"),
      search: this.state.search
    };

    await API.post("/get_agents", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ agents: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UpdateUserSearch(event) {
    await this.setState({ search: event.target.value });
    this.GetUsers();
  }

  async UpdateAgentSearch(event) {
    await this.setState({ search: event.target.value });
    this.GetAgents();
  }

  async UpdateHospitalSearch(event) {
    await this.setState({ search: event.target.value });
    this.GetHospitals();
  }

  UpdateNewUserName(event) {
    this.setState((prevState) => ({
      NewUser: {
        ...prevState.NewUser,
        name: event.target.value,
      },
    }));
  }

  UpdateNewClassification(event) {
    this.setState((prevState) => ({
      NewClassification: {
        ...prevState.NewClassification,
        name: event.target.value,
      },
    }));
  }

  UpdateNewUserPassword(event) {
    this.setState((prevState) => ({
      NewUser: {
        ...prevState.NewUser,
        password: event.target.value,
      },
    }));
  }

  UpdateNewUserEmail(event) {
    this.setState((prevState) => ({
      NewUser: {
        ...prevState.NewUser,
        email: event.target.value,
      },
    }));
  }

  UpdateNewUserPosition(value) {
    this.setState((prevState) => ({
      NewUser: {
        ...prevState.NewUser,
        position: value,
      },
    }));
  }

  NewUserModal() {
    this.setState((prevState) => ({
      NewUserModal: !this.state.NewUserModal,
      NewUser: {
        ...prevState.NewUser,
        name: "",
        email: "",
        password: "",
        position: "الوظيفة",
      },
    }));
  }

  async UploadNewUser() {
    const data = {
      UserEmail: this.state.NewUser.email,
      UserName: this.state.NewUser.name,
      UserPassword: this.state.NewUser.password,
      UserPosition: this.state.NewUser.position,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/register", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.GetUsers();
          this.GetSystemUsers();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetNewCases() {
    const data = {
      Token: window.localStorage.getItem("token")
    };

    await API.post("/new_cases_count", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ NewCases: res.data[0].count });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetSystemUsers() {
    const data = {
      Token: window.localStorage.getItem("token")
    }

    await API.post("/system_users_count", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ SystemUsers: res.data[0].count });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetAcceptedCases() {
    const data = {
      Token: window.localStorage.getItem("token")
    }

    await API.post("/accepted_cases_count", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.TokenError) window.location = "/login";
        if (res.data) this.setState({ AcceptedCases: res.data[0].count });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetAllCases() {
    const data = {
      Token: window.localStorage.getItem("token"),
    }

    await API.post("/all_cases_count", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ AllCases: res.data[0].count });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetUsers() {
    const data = {
      Token: window.localStorage.getItem("token"),
      search: this.state.search,
    };

    await API.post("/get_users", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ users: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetHospitals() {
    const data = {
      Token: window.localStorage.getItem("token"),
      search: this.state.search,
    };

    await API.post("/get_hospitals", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ hospitals: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidMount() {
    this.GetAcceptedCases();
    this.GetNewCases();
    this.GetAllCases();
    this.GetSystemUsers();
    this.GetUsers();
    this.GetAgents();
    this.GetHospitals();
    this.GetCases();
    this.GetClassifications();
    this.GetCasesBasedOnStage();
    this.GetCasesBasedOnStage();
    this.GetCasesBasedOnSubmitDate();
    this.GetCasesBasedOnAgent();
    this.GetCasesBasedOnPatientsBirthdates();
  }

  render() {
    return (
      <div className="PageContainer">
        <div className="StatisticsContainer">
          <div>عدد المستخدمين {this.state.SystemUsers}</div>
          <div>اجمالي عدد الملفات {this.state.AllCases}</div>
          <div>عدد الملفات المقبولة {this.state.AcceptedCases}</div>
          <div>عدد الملفات الجديدة {this.state.NewCases}</div>
          <div>عدد الباحثين الاجتماعيين {this.state.agents.length}</div>
        </div>

        <div style={{ width: "86%", display: "flex", margin: "auto" }}>
          <button onClick={this.ChartsTab} style={{ width: "100%", padding: "10px", borderRadius: "2px" }}>احصائيات</button>
          <button onClick={this.ClassificationsTab} style={{ width: "100%", padding: "10px", borderRadius: "2px" }}>التصنيفات</button>
          <button onClick={this.CasesTab} style={{ width: "100%", padding: "10px", borderRadius: "2px" }}>الملفات</button>
          <button onClick={this.HospitalsTab} style={{ width: "100%", padding: "10px", borderRadius: "2px" }}>المستشفيات</button>
          <button onClick={this.AgentsTab} style={{ width: "100%", padding: "10px", borderRadius: "2px" }}>الباحثين</button>
          <button onClick={this.UsersTab} style={{ width: "100%", padding: "10px", borderRadius: "2px" }}>المستخدمين</button>
        </div>

        <div className="SearchContainer" style={{ display: this.state.UsersTab === true ? null : "none", width: "96%" }}>
          <div style={{ marginLeft: "auto", marginRight: "3rem" }}>
            <input
              type="text"
              id="title-input"
              name="title-input"
              className="input"
              placeholder="ابحث باستخدام الاسم او المعرف"
              onChange={this.UpdateUserSearch}
              style={{
                fontSize: "13px",
                padding: " 7px",
                width: "350px",
              }}
            />
            <button className="sendButton" style={{ width: "auto" }} onClick={this.NewUserModal}>انشاء مستخدم جديد</button>
          </div>
        </div>

        <div className="CenterPage" style={{ width: "90%", display: this.state.UsersTab === true ? null : "none" }}>
          <div className="insideCard">
            <div className="PageContainer">
              <table className="TableContainer" style={{ width: "90%", display: this.state.UsersTab === true ? null : "none" }}>
                <thead style={{ borderBottom: "1px solid rgb(201, 201, 201)" }}>
                  <tr className="TableHeader">
                    <th>تعديل كلمة مرور المستخدم</th>
                    <th>تعديل بيانات المستخدم</th>
                    <th>اخر تحديث لبيانات المستخدم</th>
                    <th>تاريخ تسجيل المستخدم</th>
                    <th>الوظيفة</th>
                    <th>ايميل المستخدم</th>
                    <th>اسم المستخدم</th>
                    <th>معرف المستخدم</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user, i) => {
                    return (
                      <User
                        key={i}
                        id={user.u_id}
                        name={user.u_name}
                        email={user.u_email}
                        position={user.u_position}
                        RegisterDate={user.u_create_date}
                        UpdateDate={user.u_update_date}
                        GetUsers={this.GetUsers}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="SearchContainer" style={{ display: this.state.AgentsTab === true ? null : "none", width: "96%" }}>
          <div style={{ marginLeft: "auto", marginRight: "3rem" }}>
            <input
              type="text"
              id="title-input"
              name="title-input"
              className="input"
              placeholder="ابحث باستخدام الاسم او المعرف"
              onChange={this.UpdateAgentSearch}
              style={{
                fontSize: "13px",
                padding: " 7px",
                width: "350px",
              }}
            />
            <button className="sendButton" style={{ width: "auto" }} onClick={this.NewAgentModal}>انشاء باحث اجتماعي</button>
          </div>
        </div>

        <div className="CenterPage" style={{ width: "90%", display: this.state.AgentsTab === true ? null : "none" }}>
          <div className="insideCard">
            <div className="PageContainer">
              <table className="TableContainer" style={{ width: "90%", display: this.state.AgentsTab === true ? null : "none" }}>
                <thead style={{ borderBottom: "1px solid rgb(201, 201, 201)" }}>
                  <tr className="TableHeader">
                    <th>تعديل بيانات الباحث</th>
                    <th>اخر تحديث لبيانات الباحث</th>
                    <th>تاريخ تسجيل الباحث</th>
                    <th>اسم الباحث</th>
                    <th>معرف الباحث</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.agents.map((agent, i) => {
                    return (
                      <Agent
                        key={i}
                        id={agent.ag_id}
                        name={agent.ag_name}
                        RegisterDate={agent.ag_create_date}
                        UpdateDate={agent.ag_update_date}
                        GetAgents={this.GetAgents}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="SearchContainer" style={{ display: this.state.ClassificationsTab === true ? null : "none", width: "96%" }}>
          <div style={{ marginLeft: "auto", marginRight: "3rem" }}>
            {/* <input
              type="text"
              id="title-input"
              name="title-input"
              className="input"
              placeholder="ابحث باستخدام الاسم او المعرف"
              onChange={this.UpdateAgentSearch}
              style={{
                fontSize: "13px",
                padding: " 7px",
                width: "350px",
              }}
            /> */}
            <button className="sendButton" style={{ width: "auto" }} onClick={this.NewClassificationModal}>انشاء تصنيف </button>
          </div>
        </div>

        <div className="CenterPage" style={{ width: "90%", display: this.state.ClassificationsTab === true ? null : "none" }}>
          <div className="insideCard">
            <div className="PageContainer">
              <table className="TableContainer" style={{ width: "90%", display: this.state.ClassificationsTab === true ? null : "none" }}>
                <thead style={{ borderBottom: "1px solid rgb(201, 201, 201)" }}>
                  <tr className="TableHeader">
                    <th>حذف التصنيف</th>
                    <th>تاريخ تسجيل التصنيف</th>
                    <th>اسم التصنيف</th>
                    <th>معرف التصنيف</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.Classifications.map((classification, i) => {
                    return (
                      <Classification
                        key={i}
                        id={classification.cl_id}
                        name={classification.cl_name}
                        RegisterDate={classification.cl_create_date}
                        RemoveClassification={this.RemoveClassification}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="SearchContainer" style={{ display: this.state.HospitalsTab === true ? null : "none", width: "96%" }}>
          <div style={{ marginLeft: "auto", marginRight: "3rem" }}>
            <input
              type="text"
              id="title-input"
              name="title-input"
              className="input"
              placeholder="ابحث باستخدام الاسم او المعرف"
              onChange={this.UpdateHospitalSearch}
              style={{
                fontSize: "13px",
                padding: " 7px",
                width: "350px",
              }}
            />
            <button className="sendButton" style={{ width: "auto" }} onClick={this.NewHospitalModal}>انشاء مستشفى </button>
          </div>
        </div>

        <div className="CenterPage" style={{ width: "90%", display: this.state.HospitalsTab === true ? null : "none" }}>
          <div className="insideCard">
            <div className="PageContainer">
              <table className="TableContainer" style={{ width: "90%", display: this.state.HospitalsTab === true ? null : "none" }}>
                <thead style={{ borderBottom: "1px solid rgb(201, 201, 201)" }}>
                  <tr className="TableHeader">
                    <th>حذف المستشفى</th>
                    <th>تعديل بيانات المستشفى</th>
                    <th>اتفاقية المستشفى</th>
                    <th>تاريخ تسجيل المستشفى</th>
                    <th>اسم المستشفى</th>
                    <th>معرف المستشفى</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.hospitals.map((hospitals, i) => {
                    return (
                      <Hospital
                        key={i}
                        id={hospitals.h_id}
                        name={hospitals.h_name}
                        file={hospitals.h_agreement}
                        CreateDate={hospitals.h_create_date}
                        GetHospitals={this.GetHospitals}
                        RemoveHospital={this.RemoveHospital}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="SearchContainer" style={{ display: this.state.CasesTab === true ? null : "none", width: "96%" }}>
          <div style={{ marginLeft: "auto", marginRight: "3rem" }}>
            <input
              type="text"
              id="title-input"
              name="title-input"
              className="input"
              placeholder="ابحث باستخدام الاسم"
              onChange={this.UpdateCaseSearch}
              style={{
                fontSize: "13px",
                padding: " 7px",
                width: "350px",
              }}
            />
          </div>
        </div>

        <div className="CenterPage" style={{ width: "90%", display: this.state.CasesTab === true ? null : "none" }}>
          <div className="insideCard">
            <div className="PageContainer">
              <table className="TableContainer" style={{ width: "90%", display: this.state.CasesTab === true ? null : "none" }}>
                <thead style={{ borderBottom: "1px solid rgb(201, 201, 201)" }}>
                  <tr className="TableHeader">
                    <th>حذف الملف</th>
                    <th>تفاصيل الملف</th>
                    <th>تاريخ انشاء الملف</th>
                    <th>اسم المريض</th>
                    <th>معرف الملف</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.cases.map((cases, i) => {
                    return (
                      <AdminCase
                        key={i}
                        CaseID={cases.c_id}
                        CaseNumber={cases.c_number}
                        PatientName={cases.c_patient_name}
                        CreateDate={cases.c_create_date}
                        RemoveCase={this.RemoveCase}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            {this.state.index > 0 ? <FaCaretSquareLeft onClick={this.decreaseIndex} /> : null}
            {this.state.cases.length < 20 ? null : <FaCaretSquareRight onClick={this.increaseIndex} />}
          </div>
        </div>

        <div className="CenterPage" style={{ width: "90%", display: this.state.ChartsTab === true ? null : "none" }}>
          <div className="insideCard">
            <div className="PageContainer">
              <div style={{ display: "flex", textAlign: "center" }}>
                <div style={{ width: '50%' }}>
                  <p>الملفات نسبة الى تاريخ ارسالها</p>
                  <Line data={{
                    labels: Array.from(this.state.CasesBasedOnSubmitDate.labels),
                    datasets: [
                      {
                        label: 'Number of Cases Uploaded',
                        data: this.state.CasesBasedOnSubmitDate.data,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1,
                      },
                    ],
                  }} />
                </div>
                <div style={{ width: '50%' }}>
                  <p>الملفات نسبة الى المراحل</p>
                  <Bar data={{
                    labels: Array.from(this.state.CasesByStageChart.labels),
                    datasets: [
                      {
                        data: this.state.CasesByStageChart.data,
                        backgroundColor: Array.from({ length: this.state.CasesByStageChart.labels.length }, this.generateRandomColor),
                        hoverBackgroundColor: Array.from({ length: this.state.CasesByStageChart.labels.length }, this.generateRandomColor)
                      },
                    ],
                  }} />
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "20px", textAlign: "center" }}>
                <div style={{ width: '50%' }}>
                  <p>عدد المرضى بالنسبة الى اعمارهم</p>
                  <Bar data={{
                    labels: Array.from(this.state.CasesBasedOnPatientsBirthdates.labels),
                    datasets: [
                      {
                        data: this.state.CasesBasedOnPatientsBirthdates.data,
                        backgroundColor: Array.from({ length: this.state.CasesBasedOnPatientsBirthdates.labels.length }, this.generateRandomColor),
                        hoverBackgroundColor: Array.from({ length: this.state.CasesBasedOnPatientsBirthdates.labels.length }, this.generateRandomColor)
                      },
                    ],
                  }} />
                </div>
                <div style={{ width: '50%' }}>
                  <div className="input-container">
                    <select style={{ textAlign: "right" }} onChange={(e) => this.UpdateChartAgent(e.target.value)}>
                      {this.state.agents.map((agent, i) => {
                        return (
                          <option key={i} value={agent.ag_name} onChange={(e) => this.UpdateChartAgent(e.target.value)}>
                            {agent.ag_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <p>الملفات نسبة الى الباحث الاجتماعي</p>
                  <Line data={{
                    labels: Array.from(this.state.CasesBasedOnAgent.labels),
                    datasets: [
                      {
                        data: this.state.CasesBasedOnAgent.data,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1,
                      },
                    ],
                  }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.NewUserModal} onHide={this.NewUserModal} style={{ textAlign: "right" }}>
          <Modal.Header closeButton>
            <Modal.Title>انشاء مستخدم</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input">الاسم </label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  onChange={this.UpdateNewUserName}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>

              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input">الايميل </label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  onChange={this.UpdateNewUserEmail}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
              <div
                className="input-group-row"
                style={{ marginTop: "-10px" }}
              >
                <div className="input-container" style={{ paddingRight: "10px" }}>
                  <label style={{ marginTop: "10px" }}> الوظيفة</label>
                  <select className="input" onChange={(e) => this.UpdateNewUserPosition(e.target.value)}>
                    <option value="ضابط الارتباط">ضابط الارتباط</option>
                    <option value="فريق التحويل">فريق التحويل</option>
                    <option value="مسؤول التحويل">مسؤول التحويل</option>
                  </select>
                </div>
              </div>

              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input"> كلمة السر</label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  onChange={this.UpdateNewUserPassword}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.NewUserModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => { this.UploadNewUser(); this.NewUserModal() }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.NewClassificationModal} onHide={this.NewClassificationModal} style={{ textAlign: "right" }}>
          <Modal.Header closeButton>
            <Modal.Title>انشاء تصنيف </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input">اسم التصنيف </label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  onChange={this.UpdateNewClassification}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.NewClassificationModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => { this.UploadNewClassification(); this.NewClassificationModal() }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.NewAgentModal} onHide={this.NewAgentModal} style={{ textAlign: "right" }}>
          <Modal.Header closeButton>
            <Modal.Title>انشاء باحث اجتماعي</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input">اسم الباحث </label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  onChange={this.UpdateNewAgent}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.NewAgentModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => { this.UploadNewAgent(); this.NewAgentModal() }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.NewHospitalModal} onHide={this.NewHospitalModal} style={{ textAlign: "right" }}>
          <Modal.Header closeButton>
            <Modal.Title>انشاء مستشفى </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-container" style={{ paddingRight: "10px" }}>
              <label for="title-input">اسم المستشفى </label>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdateNewHospital}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "300px"
                }}
              />
              <label for="title-input">مرفقات المستشغى</label>
              <input
                id="inputFile"
                type="file"
                name="title-input"
                className="input"
                onChange={this.convertToBase64}
                style={{
                  textAlign: "right",
                  width: "auto",
                  color: "rgb(90, 90, 90)"
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.NewHospitalModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => { this.UploadNewHospital(); this.NewHospitalModal() }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default AdminPanel;

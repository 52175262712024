import React from "react";
import API from "../config/API";
import { useParams } from "react-router-dom";
import Moment from "moment";
import { Modal } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import Form from "react-bootstrap/Form";
import PDFViewer from "../components/PDFViewer";
import Comment from "../components/Comment";

const withParams = (Component) => (props) => {
  const { id } = useParams();
  return <Component {...props} id={id} />;
};

class CasesProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      agents: [],
      hospitals: [],
      ExtraHospitals: [],
      ExtraClassifications: [],
      CaseInfo: {},
      CaseComments: [],
      Classifications: [],
      UpdateCaseNumberModal: false,
      UpdateCaseNotesModal: false,
      showAlert: false,
      UpdatePatientBirthdayModal: false,
      UpdateAgentModal: false,
      UpdateClassificationModal: false,
      UpdateResultModal: false,
      UpdateHospitalModal: false,
      UpdateTransactionAmountModal: false,
      UpdateTransactionDateModal: false,
      UpdateFieldVisitDateModal: false,
      UpdateDocumentCompletionDateModal: false,
      UpdateStudyDeliveryDateModal: false,
      UpdateAcceptanceDateModal: false,
      UpdateSortDateModal: false,
      UpdateFileModal: false,
      UpdateCommentodal: false,
      UpdateFirstSortResultModal: false,
      UpdateFirstPhoneNumberModal: false,
      UpdateSecondPhoneNumberModal: false,
      UpdateSupervisorModal: false,
      UpdateTimeTransferValidityModal: false,
      UpdateExtraHospitalModal: false,
      UpdateExtraClassificationsModal: false,
      ExtraHospital: "",
      ExtraClassification: "",
      TimeTransferValidity: "",
      CaseNumber: "",
      CaseNotes: "",
      PatientName: "",
      PatientBirthday: "",
      Agent: "",
      Supervisor: "",
      Classification: "",
      Result: "",
      State: "",
      Hospital: "",
      TransactionAmount: "",
      TransactionDate: "",
      FieldVisitDate: "",
      DocumentCompletionDate: "",
      StudyDeliveryDate: "",
      AcceptanceDate: "",
      SortDate: "",
      NewFile: "",
      CaseFileCategory: "المرفقات",
      NewFileName: "",
      Comment: "",
      FirstSortResult: "",
      FirstPhoneNumber: "",
      SecondPhoneNumber: "",
    };

    // Modals
    this.UpdateCaseNumberModal = this.UpdateCaseNumberModal.bind(this);
    this.UpdateCaseNotesModal = this.UpdateCaseNotesModal.bind(this);
    this.UpdatePatientBirthdayModal = this.UpdatePatientBirthdayModal.bind(this);
    this.UpdatePatientNameModal = this.UpdatePatientNameModal.bind(this);
    this.UpdateAgentModal = this.UpdateAgentModal.bind(this);
    this.UpdateClassificationModal = this.UpdateClassificationModal.bind(this);
    this.UpdateResultModal = this.UpdateResultModal.bind(this);
    this.UpdateHospitalModal = this.UpdateHospitalModal.bind(this);
    this.UpdateTransactionAmountModal = this.UpdateTransactionAmountModal.bind(this);
    this.UpdateTransactionDateModal = this.UpdateTransactionDateModal.bind(this);
    this.UpdateFieldVisitDateModal = this.UpdateFieldVisitDateModal.bind(this);
    this.UpdateDocumentCompletionDateModal = this.UpdateDocumentCompletionDateModal.bind(this);
    this.UpdateStudyDeliveryDateModal = this.UpdateStudyDeliveryDateModal.bind(this);
    this.UpdateAcceptanceDateModal = this.UpdateAcceptanceDateModal.bind(this);
    this.UpdateSortDateModal = this.UpdateSortDateModal.bind(this);
    this.UpdateFileModal = this.UpdateFileModal.bind(this);
    this.UpdateCommentModal = this.UpdateCommentModal.bind(this);
    this.UpdateFirstSortResultModal = this.UpdateFirstSortResultModal.bind(this);
    this.UpdateFirstPhoneNumberModal = this.UpdateFirstPhoneNumberModal.bind(this);
    this.UpdateSecondPhoneNumberModal = this.UpdateSecondPhoneNumberModal.bind(this);
    this.UpdateSupervisorModal = this.UpdateSupervisorModal.bind(this);
    this.UpdateTimeTransferValidityModal = this.UpdateTimeTransferValidityModal.bind(this);
    this.UpdateAlertWindow = this.UpdateAlertWindow.bind(this);
    this.UpdateAlertWindow = this.UpdateAlertWindow.bind(this);
    this.UpdateExtraHospitalModal = this.UpdateExtraHospitalModal.bind(this);
    this.UpdateExtraClassificationsModal = this.UpdateExtraClassificationsModal.bind(this);

    // Fetching
    this.GetCaseFiles = this.GetCaseFiles.bind(this);
    this.GetCaseInfo = this.GetCaseInfo.bind(this);
    this.GetCaseComments = this.GetCaseComments.bind(this);
    this.GetAgents = this.GetAgents.bind(this);
    this.GetHospitals = this.GetHospitals.bind(this);
    this.GetClassifications = this.GetClassifications.bind(this);
    this.GetExtraHospitals = this.GetExtraHospitals.bind(this);
    this.GetExtraClassifications = this.GetExtraClassifications.bind(this);

    // Updating
    this.UpdateCaseNumber = this.UpdateCaseNumber.bind(this);
    this.UpdateCaseNotes = this.UpdateCaseNotes.bind(this);
    this.UpdatePatientBirthday = this.UpdatePatientBirthday.bind(this);
    this.UpdatePatientName = this.UpdatePatientName.bind(this);
    this.UpdateAgent = this.UpdateAgent.bind(this);
    this.UpdateClassification = this.UpdateClassification.bind(this);
    this.UpdateResultModal = this.UpdateResultModal.bind(this);
    this.UpdateResult = this.UpdateResult.bind(this);
    this.UpdateState = this.UpdateState.bind(this);
    this.UpdateHospital = this.UpdateHospital.bind(this);
    this.UpdateTransactionAmount = this.UpdateTransactionAmount.bind(this);
    this.UpdateTransactionDate = this.UpdateTransactionDate.bind(this);
    this.UpdateFieldVisitDate = this.UpdateFieldVisitDate.bind(this);
    this.UpdateDocumentCompletionDate = this.UpdateDocumentCompletionDate.bind(this);
    this.UpdateStudyDeliveryDate = this.UpdateStudyDeliveryDate.bind(this);
    this.UpdateAcceptanceDate = this.UpdateAcceptanceDate.bind(this);
    this.UpdateSortDate = this.UpdateSortDate.bind(this);
    this.UpdateComment = this.UpdateComment.bind(this);
    this.UpdateNewFile = this.UpdateNewFile.bind(this);
    this.UpdateFirstSortResult = this.UpdateFirstSortResult.bind(this);
    this.UpdateFirstPhoneNumber = this.UpdateFirstPhoneNumber.bind(this);
    this.UpdateSecondPhoneNumber = this.UpdateSecondPhoneNumber.bind(this);
    this.UpdateSupervisor = this.UpdateSupervisor.bind(this);
    this.UpdateTimeTransferValidity = this.UpdateTimeTransferValidity.bind(this);
    this.UpdateFileCategory = this.UpdateFileCategory.bind(this)
    this.UpdateExtraHospital = this.UpdateExtraHospital.bind(this)
    this.UpdateExtraClassification = this.UpdateExtraClassification.bind(this)

    // file process
    this.convertToBase64 = this.convertToBase64.bind(this);
    this.UpdateFileName = this.UpdateFileName.bind(this);

    // Uploading
    this.UploadCaseNumber = this.UploadCaseNumber.bind(this);
    this.UploadCaseNotes = this.UploadCaseNotes.bind(this);
    this.UploadPatientBirthday = this.UploadPatientBirthday.bind(this);
    this.UploadPatientName = this.UploadPatientName.bind(this);
    this.UploadAgent = this.UploadAgent.bind(this);
    this.UploadClassification = this.UploadClassification.bind(this);
    this.UploadResult = this.UploadResult.bind(this);
    this.UploadHospital = this.UploadHospital.bind(this);
    this.UploadTransactionAmount = this.UploadTransactionAmount.bind(this);
    this.UploadTransactionDate = this.UploadTransactionDate.bind(this);
    this.UploadFieldVisitDate = this.UploadFieldVisitDate.bind(this);
    this.UploadDocumentCompletionDate = this.UploadDocumentCompletionDate.bind(this);
    this.UploadStudyDeliveryDate = this.UploadStudyDeliveryDate.bind(this);
    this.UploadAcceptanceDate = this.UploadAcceptanceDate.bind(this);
    this.UploadSortDate = this.UploadSortDate.bind(this);
    this.UploadFile = this.UploadFile.bind(this);
    this.UploadComment = this.UploadComment.bind(this);
    this.UploadFirstSortResult = this.UploadFirstSortResult.bind(this);
    this.UploadFirstPhoneNumber = this.UploadFirstPhoneNumber.bind(this);
    this.UploadSecondPhoneNumber = this.UploadSecondPhoneNumber.bind(this);
    this.UploadSupervisor = this.UploadSupervisor.bind(this);
    this.UploadTimeTransferValidity = this.UploadTimeTransferValidity.bind(this);
    this.UploadNewCaseFile = this.UploadNewCaseFile.bind(this);
    this.UploadExtraHospital = this.UploadExtraHospital.bind(this);
    this.UploadExtraClassification = this.UploadExtraClassification.bind(this);

    // Deleting

    this.DeleteExtraHospital = this.DeleteExtraHospital.bind(this);
    this.DeleteExtraClassification = this.DeleteExtraClassification.bind(this);

    // Other
    this.CalculateAge = this.CalculateAge.bind(this);
  }

  UpdateExtraClassificationsModal() {
    this.setState({ UpdateExtraClassificationsModal: !this.state.UpdateExtraClassificationsModal })
  }

  UpdateExtraClassification(value) {
    this.setState({ ExtraClassification: value })
  }

  async DeleteExtraHospital(id) {
    const data = {
      HospitalID: id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/remove_extra_hospital", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetExtraHospitals();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async DeleteExtraClassification(id) {
    const data = {
      ClassificationID: id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/remove_extra_classification", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetExtraClassifications();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetExtraClassifications() {
    const data = {
      CaseID: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_extra_classifications", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ ExtraClassifications: res.data })
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  UpdateExtraHospital(value) {
    this.setState({ ExtraHospital: value })
  }

  UpdateExtraHospitalModal() {
    this.setState({ UpdateExtraHospitalModal: !this.state.UpdateExtraHospitalModal })
  }

  UpdateAlertWindow() {
    this.setState({ showAlert: !this.state.showAlert })
  }

  async UploadNewCaseFile(S3SignedURL) {
    try {
      const CaseFile = this.state.NewFile;

      this.UpdateFileModal();

      const sendToS3 = await fetch(S3SignedURL, {
        method: 'PUT',
        body: CaseFile,
      });

      if (sendToS3.ok) {
        return true;
      } else {
        console.error('Error uploading file:', sendToS3.statusText);
        return false;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return false;
    }
  }

  UpdateFileCategory(value) {
    this.setState({ CaseFileCategory: value });
  }

  async GetClassifications() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_classifications", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ Classifications: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadExtraHospital() {
    const data = {
      HospitalName: this.state.ExtraHospital,
      HospitalCase: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/add_extra_hospital", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.GetExtraHospitals();
          this.UpdateExtraHospitalModal();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadExtraClassification() {
    const data = {
      ClassificationName: this.state.ExtraClassification,
      ClassificationCase: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/add_extra_classification", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.GetExtraClassifications();
          this.UpdateExtraClassificationsModal();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetExtraHospitals() {
    const data = {
      CaseID: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_extra_hospitals", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ ExtraHospitals: res.data })
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  UpdateTimeTransferValidityModal() {
    this.setState({
      UpdateTimeTransferValidityModal: !this.state.UpdateTimeTransferValidityModal,
      TimeTransferValidity: "",
    });
  }

  UpdateTimeTransferValidity(event) {
    event.preventDefault();
    this.setState({ TimeTransferValidity: event.target.value });
  }

  async GetAgents() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_agents", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ agents: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetHospitals() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_hospitals", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ hospitals: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadTimeTransferValidity() {
    const data = {
      CaseID: this.props.id,
      TimeTransferValidity: this.state.TimeTransferValidity,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateTimeTransferValidityModal();
    this.UpdateAlertWindow();

    await API.post("/update_time_transfer_validity", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadSupervisor() {
    const data = {
      CaseID: this.props.id,
      Supervisor: this.state.Supervisor,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateSupervisorModal();
    this.UpdateAlertWindow();

    await API.post("/update_supervisor", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  UpdateSupervisor(event) {
    event.preventDefault();
    this.setState({ Supervisor: event.target.value });
  }

  UpdateSupervisorModal() {
    this.setState({
      UpdateSupervisorModal: !this.state.UpdateSupervisorModal,
      Supervisor: "",
    });
  }

  UpdateFirstPhoneNumberModal() {
    this.setState({
      UpdateFirstPhoneNumberModal: !this.state.UpdateFirstPhoneNumberModal,
      FirstPhoneNumber: "",
    });
  }

  UpdateSecondPhoneNumberModal() {
    this.setState({
      UpdateSecondPhoneNumberModal: !this.state.UpdateSecondPhoneNumberModal,
      SecondPhoneNumber: "",
    });
  }

  UpdateFirstPhoneNumber(event) {
    event.preventDefault();
    this.setState({ FirstPhoneNumber: event.target.value });
  }

  UpdateSecondPhoneNumber(event) {
    event.preventDefault();
    this.setState({ SecondPhoneNumber: event.target.value });
  }

  async UploadFirstSortResult() {
    const data = {
      CaseID: this.props.id,
      FirstSortResult: this.state.FirstSortResult,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateFirstSortResultModal();
    this.UpdateAlertWindow();

    await API.post("/update_first_sort_result", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadFirstPhoneNumber() {
    const data = {
      CaseID: this.props.id,
      FirstPhoneNumber: this.state.FirstPhoneNumber,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateFirstPhoneNumberModal();
    this.UpdateAlertWindow();

    await API.post("/update_first_phone_number", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadSecondPhoneNumber() {
    const data = {
      CaseID: this.props.id,
      SecondPhoneNumber: this.state.SecondPhoneNumber,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateSecondPhoneNumberModal();
    this.UpdateAlertWindow();

    await API.post("/update_second_phone_number", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  UpdateFirstSortResultModal() {
    this.setState({
      UpdateFirstSortResultModal: !this.state.UpdateFirstSortResultModal,
      FirstSortResult: "",
    });
  }

  UpdateFirstSortResult(event) {
    event.preventDefault();
    this.setState({ FirstSortResult: event.target.value });
  }

  CalculateAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  convertToBase64(event) {
    event.preventDefault();
    const selectedFile = document.getElementById("inputFile").files;

    if (selectedFile.length > 0) {
      const fileToLoad = selectedFile[0];
      const fileReader = new FileReader();
      const self = this;

      fileReader.onload = async function (fileLoadedEvent) {
        const base64 = fileLoadedEvent.target.result;
        const fileName = event.target.files[0].name;
        self.UpdateNewFile(base64);
        self.UpdateFileName(fileName);
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  UpdateNewFile(value) {
    this.setState({ NewFile: value });
  }

  UpdateFileName(value) {
    this.setState({ NewFileName: value });
  }

  UpdateComment(event) {
    this.setState({ Comment: event.target.value });
  }

  async UploadComment() {
    const data = {
      CaseID: this.props.id,
      CommentText: this.state.Comment,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateCommentModal();
    this.UpdateAlertWindow();

    await API.post("/add_comment", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseComments();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetCaseComments() {
    const data = {
      CaseID: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_comments", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.setState({ CaseComments: res.data });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  UpdateCommentModal() {
    this.setState({
      UpdateCommentModal: !this.state.UpdateCommentModal,
      Comment: "",
    });
  }

  UpdatePatientNameModal() {
    this.setState({
      UpdatePatientNameModal: !this.state.UpdatePatientNameModal,
      PatientName: "",
    });
  }

  UpdateCaseNumberModal() {
    this.setState({
      UpdateCaseNumberModal: !this.state.UpdateCaseNumberModal,
      CaseNumber: "",
    });
  }

  UpdateCaseNotesModal() {
    this.setState({
      UpdateCaseNotesModal: !this.state.UpdateCaseNotesModal,
      CaseNotes: "",
    });
  }

  UpdatePatientBirthdayModal() {
    this.setState({
      UpdatePatientBirthdayModal: !this.state.UpdatePatientBirthdayModal,
      PatientBirthday: "",
    });
  }

  UpdateAgentModal() {
    this.setState({
      UpdateAgentModal: !this.state.UpdateAgentModal,
      Agent: "",
    });
  }

  UpdateClassificationModal() {
    this.setState({
      UpdateClassificationModal: !this.state.UpdateClassificationModal,
      Classification: "",
    });
  }

  UpdateResultModal() {
    this.setState({
      UpdateResultModal: !this.state.UpdateResultModal,
      Result: "",
    });
  }

  UpdateHospitalModal() {
    this.setState({
      UpdateHospitalModal: !this.state.UpdateHospitalModal,
      Hospital: "",
    });
  }

  UpdateTransactionAmountModal() {
    this.setState({
      UpdateTransactionAmountModal: !this.state.UpdateTransactionAmountModal,
      TransactionAmount: "",
    });
  }

  UpdateTransactionDateModal() {
    this.setState({
      UpdateTransactionDateModal: !this.state.UpdateTransactionDateModal,
      TransactionDate: "",
    });
  }

  UpdateFieldVisitDateModal() {
    this.setState({
      UpdateFieldVisitDateModal: !this.state.UpdateFieldVisitDateModal,
      FieldVisitDate: "",
    });
  }

  UpdateDocumentCompletionDateModal() {
    this.setState({
      UpdateDocumentCompletionDateModal:
        !this.state.UpdateDocumentCompletionDateModal,
      DocumentCompletionDate: "",
    });
  }

  UpdateStudyDeliveryDateModal() {
    this.setState({
      UpdateStudyDeliveryDateModal: !this.state.UpdateStudyDeliveryDateModal,
      StudyDeliveryDate: "",
    });
  }

  UpdateAcceptanceDateModal() {
    this.setState({
      UpdateAcceptanceDateModal: !this.state.UpdateAcceptanceDateModal,
      AcceptanceDate: "",
    });
  }

  UpdateSortDateModal() {
    this.setState({
      UpdateSortDateModal: !this.state.UpdateSortDateModal,
      SortDate: "",
    });
  }

  UpdateFileModal() {
    this.setState({
      UpdateFileModal: !this.state.UpdateFileModal,
      NewFile: "",
      NewFileName: "",
    });
  }

  UpdatePatientName(event) {
    event.preventDefault();
    this.setState({ PatientName: event.target.value });
  }

  UpdateCaseNumber(event) {
    event.preventDefault();
    this.setState({ CaseNumber: event.target.value });
  }

  UpdateCaseNotes(event) {
    event.preventDefault();
    this.setState({ CaseNotes: event.target.value });
  }

  UpdatePatientBirthday(event) {
    event.preventDefault();
    this.setState({ PatientBirthday: event.target.value });
  }

  UpdateAgent(value) {
    this.setState({ Agent: value });
  }

  UpdateClassification(event) {
    event.preventDefault();
    this.setState({ Classification: event.target.value });
  }

  UpdateResult(event) {
    event.preventDefault();
    this.setState({ Result: event.target.value });
  }

  UpdateState(value) {
    this.setState({ State: value });
  }

  UpdateHospital(value) {
    this.setState({ Hospital: value });
  }

  UpdateTransactionAmount(event) {
    event.preventDefault();
    this.setState({ TransactionAmount: event.target.value });
  }

  UpdateTransactionDate(event) {
    event.preventDefault();
    this.setState({ TransactionDate: event.target.value });
  }

  UpdateFieldVisitDate(event) {
    event.preventDefault();
    this.setState({ FieldVisitDate: event.target.value });
  }

  UpdateDocumentCompletionDate(event) {
    event.preventDefault();
    this.setState({ DocumentCompletionDate: event.target.value });
  }

  UpdateStudyDeliveryDate(event) {
    event.preventDefault();
    this.setState({ StudyDeliveryDate: event.target.value });
  }

  UpdateAcceptanceDate(event) {
    event.preventDefault();
    this.setState({ AcceptanceDate: event.target.value });
  }

  UpdateSortDate(event) {
    event.preventDefault();
    this.setState({ SortDate: event.target.value });
  }

  async UploadPatientName() {
    const data = {
      CaseID: this.props.id,
      PatientName: this.state.PatientName,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdatePatientNameModal();
    this.UpdateAlertWindow();

    await API.post("/update_patient_name", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadCaseNumber() {
    const data = {
      CaseID: this.props.id,
      CaseNumber: this.state.CaseNumber,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateCaseNumberModal();
    this.UpdateAlertWindow();

    await API.post("/update_case_number", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadCaseNotes() {
    const data = {
      CaseID: this.props.id,
      CaseNotes: this.state.CaseNotes,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateCaseNotesModal();
    this.UpdateAlertWindow();

    await API.post("/update_case_notes", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadPatientBirthday() {
    const data = {
      CaseID: this.props.id,
      PatientBirthday: this.state.PatientBirthday,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdatePatientBirthdayModal();
    this.UpdateAlertWindow();

    await API.post("/update_patient_birthday", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadAgent() {
    const data = {
      CaseID: this.props.id,
      Agent: this.state.Agent,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateAgentModal();
    this.UpdateAlertWindow();

    await API.post("/update_agent", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadClassification() {
    const data = {
      CaseID: this.props.id,
      Classification: this.state.Classification,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateClassificationModal();
    this.UpdateAlertWindow();

    await API.post("/update_classification", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadResult() {
    const data = {
      CaseID: this.props.id,
      CasePatient: this.state.CaseInfo.c_patient_name,
      Result: this.state.Result,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateResultModal();
    this.UpdateAlertWindow();

    await API.post("/update_stage", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadHospital() {
    const data = {
      CaseID: this.props.id,
      Hospital: this.state.Hospital,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateHospitalModal();
    this.UpdateAlertWindow();

    await API.post("/update_hospital", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadTransactionAmount() {
    const data = {
      CaseID: this.props.id,
      TransactionAmount: this.state.TransactionAmount,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateTransactionAmountModal();
    this.UpdateAlertWindow();

    await API.post("/update_transaction_amount", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadTransactionDate() {
    const data = {
      CaseID: this.props.id,
      TransactionDate: this.state.TransactionDate,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateTransactionDateModal();
    this.UpdateAlertWindow();

    await API.post("/update_transaction_date", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadFieldVisitDate() {
    const data = {
      CaseID: this.props.id,
      FieldVisitDate: this.state.FieldVisitDate,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateFieldVisitDateModal()
    this.UpdateAlertWindow();

    await API.post("/update_field_visit_date", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadDocumentCompletionDate() {
    const data = {
      CaseID: this.props.id,
      DocumentCompletionDate: this.state.DocumentCompletionDate,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateDocumentCompletionDateModal();
    this.UpdateAlertWindow();

    await API.post("/update_document_completion_date", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadStudyDeliveryDate() {
    const data = {
      CaseID: this.props.id,
      StudyDeliveryDate: this.state.StudyDeliveryDate,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateStudyDeliveryDateModal();
    this.UpdateAlertWindow();

    await API.post("/update_study_delivery_date", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadAcceptanceDate() {
    const data = {
      CaseID: this.props.id,
      AcceptanceDate: this.state.AcceptanceDate,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateAcceptanceDateModal();
    this.UpdateAlertWindow();

    await API.post("/update_acceptance_date", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadSortDate() {
    const data = {
      CaseID: this.props.id,
      SortDate: this.state.SortDate,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateSortDateModal();
    this.UpdateAlertWindow();

    await API.post("/update_sort_date", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.GetCaseInfo();
        this.UpdateAlertWindow();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UploadFile() {
    const data = {
      CaseID: this.props.id,
      CaseFileName: this.state.NewFileName,
      CaseFileCategory: this.state.CaseFileCategory,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateAlertWindow();

    await API.post("/add_new_file", data).then((respone) => {
      const res = respone.data;
      if (res.ErrorMessage) window.alert(res.ErrorMessage);
      if (res.data) {
        (async () => {
          const uplaodtos3 = await this.UploadNewCaseFile(res.data);
          if (uplaodtos3) {
            this.GetCaseFiles();
            this.UpdateAlertWindow();
          }
        })();
      }
    })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetCaseInfo() {
    const data = {
      Token: window.localStorage.getItem("token"),
      CaseID: this.props.id
    };

    await API.post("/get_case_info", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ CaseInfo: res.data[0] });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async GetCaseFiles() {
    const data = {
      Token: window.localStorage.getItem("token"),
      CaseID: this.props.id
    };

    this.setState({ files: [] });

    await API.post("/get_case_files", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ files: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidMount() {
    this.GetCaseInfo();
    this.GetCaseFiles();
    this.GetCaseComments();
    this.GetAgents();
    this.GetHospitals();
    this.GetClassifications();
    this.GetExtraHospitals();
    this.GetExtraClassifications();
  }

  render() {
    return (
      <>
        <div className="PageContainer">
          <div className="CaseProfile">

            <div className="Section">
              <div className="InfoCard">
                <h5>رقم الهاتف الثاني <BiEdit onClick={this.UpdateSecondPhoneNumberModal} /></h5>
                <p>
                  {!this.state.CaseInfo.c_second_phone_number ? "مجهول" : this.state.CaseInfo.c_second_phone_number}
                </p>
              </div>

              <div className="InfoCard">
                <h5>رقم الهاتف الأول <BiEdit onClick={this.UpdateFirstPhoneNumberModal} /></h5>
                <p>
                  {!this.state.CaseInfo.c_first_phone_number ? "مجهول" : this.state.CaseInfo.c_first_phone_number}
                </p>
              </div>

              <div className="InfoCard">
                <h5>عمر المريض <BiEdit onClick={this.UpdatePatientBirthdayModal} /></h5>
                <p>
                  {!this.state.CaseInfo.c_patient_birthday
                    ? "مجهول"
                    : this.CalculateAge(Moment(this.state.CaseInfo.c_patient_birthday).format("MM/DD/YYYY"))
                  }
                </p>
              </div>

              <div className="InfoCard">
                <h5>الباحثة المكلفة <BiEdit onClick={this.UpdateAgentModal} /></h5>
                <p>
                  {!this.state.CaseInfo.c_agent ? "مجهول" : this.state.CaseInfo.c_agent}
                </p>
              </div>

              <div className="InfoCard">
                <h5>اسم المريض <BiEdit onClick={this.UpdatePatientNameModal} /></h5>
                <p>
                  {!this.state.CaseInfo.c_patient_name ? "مجهول" : this.state.CaseInfo.c_patient_name}
                </p>
              </div>

              <div className="InfoCard">
                <h5>رقم الملف <BiEdit onClick={this.UpdateCaseNumberModal} /></h5>
                <p>
                  {!this.state.CaseInfo.c_number ? "مجهول" : this.state.CaseInfo.c_number}
                </p>
              </div>
            </div>

            <div className="Section">
              <div className="InfoCard">
                <h5>
                  تصنيف الملف{" "}
                  <BiEdit onClick={this.UpdateClassificationModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_classification
                    ? "مجهول"
                    : this.state.CaseInfo.c_classification}
                </p>
              </div>

              <div className="InfoCard">
                <h5>
                  تاريخ التحويل{" "}
                  <BiEdit onClick={this.UpdateTransactionDateModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_transaction_date
                    ? "مجهول"
                    : Moment(this.state.CaseInfo.c_transaction_date).format(
                      "DD/MM/YYYY"
                    )}
                </p>
              </div>

              <div className="InfoCard">
                <h5>
                  تاريخ الزيارة الميدانية{" "}
                  <BiEdit onClick={this.UpdateFieldVisitDateModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_field_visit_date
                    ? "مجهول"
                    : Moment(this.state.CaseInfo.c_field_visit_date).format(
                      "DD/MM/YYYY"
                    )}
                </p>
              </div>

              <div className="InfoCard">
                <h5>
                  صلاحية التحويل الزمني
                  <BiEdit onClick={this.UpdateTimeTransferValidityModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_time_transfer_validity
                    ? "مجهول"
                    : Moment(this.state.CaseInfo.c_time_transfer_validity).format(
                      "DD/MM/YYYY"
                    )}
                </p>
              </div>

              <div className="InfoCard">
                <h5>
                  المستشفى <BiEdit onClick={this.UpdateHospitalModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_hospital
                    ? "مجهول"
                    : this.state.CaseInfo.c_hospital}
                </p>
                <p></p>
              </div>

              <div className="InfoCard">
                <h5>
                  سقف التحويل{" "}
                  <BiEdit onClick={this.UpdateTransactionAmountModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_transaction_amount
                    ? "مجهول"
                    : this.state.CaseInfo.c_transaction_amount}
                </p>
              </div>
            </div>

            <div className="Section">
              <div className="InfoCard">
                <h5>
                  تاريخ استكمال الأوراق{" "}
                  <BiEdit onClick={this.UpdateDocumentCompletionDateModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_document_completion_date
                    ? "مجهول"
                    : Moment(
                      this.state.CaseInfo.c_document_completion_date
                    ).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="InfoCard">
                <h5>
                  تاريخ تسليم الدراسة{" "}
                  <BiEdit onClick={this.UpdateStudyDeliveryDateModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_study_delivery_date
                    ? "مجهول"
                    : Moment(this.state.CaseInfo.c_study_delivery_date).format(
                      "DD/MM/YYYY"
                    )}
                </p>
              </div>

              <div className="InfoCard">
                <h5>
                  تاريخ قرار الفرز <BiEdit onClick={this.UpdateSortDateModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_sort_date
                    ? "مجهول"
                    : Moment(this.state.CaseInfo.c_sort_date).format(
                      "DD/MM/YYYY"
                    )}
                </p>
              </div>
              <div className="InfoCard">
                <h5>
                  مرحلة الملف <BiEdit onClick={this.UpdateResultModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_stage
                    ? "مجهول"
                    : this.state.CaseInfo.c_stage}
                </p>
              </div>

              <div className="InfoCard">
                <h5>
                  قرار الفرز الأولي{" "}
                  <BiEdit onClick={this.UpdateFirstSortResultModal} />
                </h5>
                <p>
                  {!this.state.CaseInfo.c_first_sort_result
                    ? "مجهول"
                    : this.state.CaseInfo.c_first_sort_result}
                </p>
              </div>

              <div className="InfoCard">
                <h5>
                  حالة الملف
                </h5>
                <p>
                  {!this.state.CaseInfo.c_state ? "مجهول" : this.state.CaseInfo.c_state}
                </p>
              </div>
            </div>

            <div style={{ margin: "auto", width: "90%", padding: 10 }}>
              <button style={{ padding: 5 }} onClick={this.UpdateExtraHospitalModal}>ادخال مستشفى اضافي </button>
            </div>

            <div className="Section">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                {this.state.ExtraHospitals.length === 0 ? (
                  "لا يوجد مستشفيات اضافية "
                ) : (
                  <>
                    <h5>المستشفيات الاضافية</h5>
                    {this.state.ExtraHospitals.map((hospital, i) => (
                      <div
                        key={i}
                        style={{
                          display: "inline-block",
                          margin: "10px",
                          textAlign: "center",
                        }}>
                        <p>
                          {hospital.h_name}
                          <IoClose color="black" onClick={() => { this.DeleteExtraHospital(hospital.h_id); }} style={{}} />
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>

            <div style={{ margin: "auto", width: "90%", padding: 10 }}>
              <button style={{ padding: 5 }} onClick={this.UpdateExtraClassificationsModal}>ادخال تصنيف اضافي </button>
            </div>

            <div className="Section">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                {this.state.ExtraClassifications.length === 0 ? (
                  "لا يوجد تصنيفات اضافية "
                ) : (
                  <>
                    <h5>التصنيفات الاضافية</h5>
                    {this.state.ExtraClassifications.map((classification, i) => (
                      <div
                        key={i}
                        style={{
                          display: "inline-block",
                          margin: "10px",
                          textAlign: "center",
                        }}>
                        <p>
                          {classification.cl_name}
                          <IoClose color="black" onClick={() => { this.DeleteExtraClassification(classification.cl_id); }} style={{}} />
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>

            <div className="Section">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                <h5>
                  ملاحظات <BiEdit onClick={this.UpdateCaseNotesModal} />
                </h5>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {this.state.CaseInfo.c_notes === ""
                    ? "لا يوجد ملاحظات"
                    : this.state.CaseInfo.c_notes}
                </pre>
              </div>
            </div>

            {(() => {
              if (this.state.CaseInfo.c_not_study_reason !== null) {
                return (
                  <div className="Section">
                    <div
                      className="InfoCard"
                      style={{
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: 0,
                        textAlign: "right",
                        paddingRight: "6.5%",
                      }}
                    >
                      <h5>
                        سبب عدم الدراسة
                      </h5>
                      <p>
                        {this.state.CaseInfo.c_not_study_reason}
                      </p>
                    </div>
                  </div>
                )
              }
            })()}

            <div style={{ margin: "auto", width: "90%", padding: 10 }}>
              <button style={{ padding: 5 }} onClick={this.UpdateFileModal}>ادخال ملف جديد</button>
            </div>

            <div className="Section">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                {!this.state.files ? (
                  "لا يوجد مرفقات"
                ) : (
                  <>
                    <h5>المرفقات</h5>
                    {this.state.files.map((file, i) => {
                      if (file.f_category === "المرفقات") {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "inline-block",
                              margin: "10px",
                              textAlign: "center",
                            }}
                          >
                            <PDFViewer
                              type="case_file"
                              size={50}
                              file={file.f_content}
                              FileName={file.f_name}
                              Category={file.f_category}
                              id={file.f_id}
                              GetCaseFiles={this.GetCaseFiles}
                            />
                          </div>
                        )
                      } else {
                        return (
                          <></>
                        )
                      }
                    }
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="Section">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                {!this.state.files ? (
                  "لا يوجد كتب تحويل"
                ) : (
                  <>
                    <h5>كتب التحويل</h5>
                    {this.state.files.map((file, i) => {
                      if (file.f_category === "كتب التحويل") {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "inline-block",
                              margin: "10px",
                              textAlign: "center",
                            }}
                          >
                            <PDFViewer
                              type="case_file"
                              size={50}
                              file={file.f_content}
                              FileName={file.f_name}
                              Category={file.f_category}
                              id={file.f_id}
                              GetCaseFiles={this.GetCaseFiles}
                            />
                          </div>
                        )
                      } else {
                        return (
                          <></>
                        )
                      }
                    }
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="Section">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                {!this.state.files ? (
                  "لا يوجد فواتير للمريض"
                ) : (
                  <>
                    <h5>فواتير المريض</h5>
                    {this.state.files.map((file, i) => {
                      if (file.f_category === "فواتير المريض") {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "inline-block",
                              margin: "10px",
                              textAlign: "center",
                            }}
                          >
                            <PDFViewer
                              type="case_file"
                              size={50}
                              file={file.f_content}
                              FileName={file.f_name}
                              Category={file.f_category}
                              id={file.f_id}
                              GetCaseFiles={this.GetCaseFiles}
                            />
                          </div>
                        )
                      } else {
                        return (
                          <></>
                        )
                      }
                    }
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="Section">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                {!this.state.files ? (
                  "لا يوجد مرفقات لتجديد العلاج "
                ) : (
                  <>
                    <h5>مرفقات تجديد العلاج</h5>
                    {this.state.files.map((file, i) => {
                      if (file.f_category === "تجديد العلاج") {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "inline-block",
                              margin: "10px",
                              textAlign: "center",
                            }}
                          >
                            <PDFViewer
                              type="case_file"
                              size={50}
                              file={file.f_content}
                              FileName={file.f_name}
                              Category={file.f_category}
                              id={file.f_id}
                              GetCaseFiles={this.GetCaseFiles}
                            />
                          </div>
                        )
                      } else {
                        return (
                          <></>
                        )
                      }
                    }
                    )}
                  </>
                )}
              </div>
            </div>

            <div style={{ margin: "auto", width: "90%", padding: 10 }}>
              <button style={{ padding: 5 }} onClick={this.UpdateCommentModal}>ادخال تعليق جديد</button>
            </div>

            <div className="CommentsSection">
              <div
                className="InfoCard"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: 0,
                  textAlign: "right",
                  paddingRight: "6.5%",
                }}
              >
                <h5>التعليقات</h5>
                {this.state.CaseComments.map((comment, i) => (
                  <Comment
                    key={i}
                    id={comment.ct_id}
                    Text={comment.ct_text}
                    Author={comment.u_name}
                    BelongToUser={comment.BelongToUser}
                    CreateDate={comment.ct_create_date}
                    getComments={this.GetCaseComments}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.showAlert} onHide={this.UpdateAlertWindow} backdrop="static">
          <Modal.Body>
            جاري ارسال الملف
          </Modal.Body>
        </Modal>

        <Modal show={this.state.UpdateCaseNumberModal} onHide={this.UpdateCaseNumberModal}>
          <Modal.Header>
            <Modal.Title>تحديث رقم الملف</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-container" style={{ paddingRight: "10px" }}>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                defaultValue={this.state.CaseInfo.c_number}
                onChange={this.UpdateCaseNumber}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "300px"
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateCaseNumberModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadCaseNumber()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateSupervisorModal} onHide={this.UpdateSupervisorModal}>
          <Modal.Header>
            <Modal.Title>تحديث المتابع</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="text"
                  autoFocus
                  defaultValue={this.state.CaseInfo.c_supervisor}
                  onChange={this.UpdateSupervisor}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateSupervisorModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadSupervisor()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateFirstPhoneNumberModal} onHide={this.UpdateFirstPhoneNumberModal}>
          <Modal.Header>
            <Modal.Title>تحديث رقم الهاتف الاول</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-container" style={{ paddingRight: "10px" }}>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                defaultValue={this.state.CaseInfo.c_first_phone_number}
                onChange={this.UpdateFirstPhoneNumber}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "300px"
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateFirstPhoneNumberModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadFirstPhoneNumber()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateSecondPhoneNumberModal} onHide={this.UpdateSecondPhoneNumberModal}>
          <Modal.Header>
            <Modal.Title>تحديث رقم الهاتف الثاني</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-container" style={{ paddingRight: "10px" }}>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                defaultValue={this.state.CaseInfo.c_second_phone_number}
                onChange={this.UpdateSecondPhoneNumber}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "300px"
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateSecondPhoneNumberModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadSecondPhoneNumber()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdatePatientNameModal} onHide={this.UpdatePatientNameModal}>
          <Modal.Header>
            <Modal.Title>تحديث اسم المريض</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-container" style={{ paddingRight: "10px" }}>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                defaultValue={this.state.CaseInfo.c_patient_name}
                onChange={this.UpdatePatientName}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "300px"
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdatePatientNameModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadPatientName()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdatePatientBirthdayModal} onHide={this.UpdatePatientBirthdayModal}>
          <Modal.Header>
            <Modal.Title>تحديث تاريخ ميلاد المريض</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-container">
              <input
                type="date"
                id="title-input"
                name="title-input"
                className="input"
                defaultValue={this.state.CaseInfo.c_patient_birthday}
                onChange={this.UpdatePatientBirthday}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "auto",
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdatePatientBirthdayModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadPatientBirthday()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateAgentModal} onHide={this.UpdateAgentModal}>
          <Modal.Header>
            <Modal.Title>تحديث الباحثة المكلفة</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label>&zwnj;</label>
                <select className="input" onClick={(e) => this.UpdateAgent(e.target.value)}>
                  {this.state.agents.map((agent, i) => {
                    return (
                      <option key={i} value={agent.ag_name} required>
                        {agent.ag_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateAgentModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadAgent()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateClassificationModal} onHide={this.UpdateClassificationModal}>
          <Modal.Header>
            <Modal.Title>تحديث تصنيف الملف</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label>&zwnj;</label>
                <select className="input" defaultValue={this.state.CaseInfo.c_classification} onChange={this.UpdateClassification}>
                  {this.state.Classifications.map((Classification, i) => {
                    return (
                      <option key={i} value={Classification.cl_name} required>
                        {Classification.cl_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateClassificationModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadClassification()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateResultModal} onHide={this.UpdateResultModal}>
          <Modal.Header>
            <Modal.Title>تحديث مرحلة الملف</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label>&zwnj;</label>
                <select className="input" defaultValue={this.state.CaseInfo.c_stage} onChange={this.UpdateResult}>
                  <option value="جاهز للتحويل">دراسات جاهزة للتحويل</option>
                  <option value="جديد">دراسات جديدة</option>
                  <option value="عالق">دراسات عالقة</option>
                  <option value="دراسات محولة - شبكات خارجية">دراسات محولة - شبكات خارجية</option>
                  <option value="محولة">دراسات محولة</option>
                  <option value="لن تحول">دراسات لن تحول</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateResultModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadResult()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateFirstSortResultModal} onHide={this.UpdateFirstSortResultModal}>
          <Modal.Header>
            <Modal.Title>قرار الفرز الاولي</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label>&zwnj;</label>
                <select className="input" defaultValue={this.state.CaseInfo.c_first_sort_result} onChange={this.UpdateFirstSortResult}>
                  <option value="دراسة">دراسة</option>
                  <option value="عدم دراسة">عدم دراسة</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateFirstSortResultModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadFirstSortResult()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateHospitalModal} onHide={this.UpdateHospitalModal}>
          <Modal.Header>
            <Modal.Title>تحديث المستشفى</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label>&zwnj;</label>
                <select className="input" onClick={(e) => this.UpdateHospital(e.target.value)}>
                  {this.state.hospitals.map((hospital, i) => {
                    return (
                      <option key={i} value={hospital.h_name} required>
                        {hospital.h_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateHospitalModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadHospital()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateTransactionAmountModal} onHide={this.UpdateTransactionAmountModal}>
          <Modal.Header>
            <Modal.Title>تحديث مقدرا التحويل</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="text"
                  autoFocus
                  defaultValue={this.state.CaseInfo.c_transaction_amount}
                  onChange={this.UpdateTransactionAmount}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateTransactionAmountModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadTransactionAmount()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateCommentModal} onHide={this.UpdateCommentModal}>
          <Modal.Header>
            <Modal.Title>اضافة تعليق</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <textarea
                  autoFocus
                  onChange={this.UpdateComment}
                  style={{
                    textAlign: "right",
                    width: "100%",
                    height: "300px"
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateCommentModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={this.UploadComment}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateTransactionDateModal} onHide={this.UpdateTransactionDateModal}>
          <Modal.Header>
            <Modal.Title>تحديث تاريخ التحويل</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="date"
                  autoFocus
                  defaultValue={this.state.CaseInfo.c_transaction_date}
                  onChange={this.UpdateTransactionDate}
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateTransactionDateModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={this.UploadTransactionDate}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateFieldVisitDateModal} onHide={this.UpdateFieldVisitDateModal}>
          <Modal.Header>
            <Modal.Title>تحديث تاريخ الزيارةالميدانية</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="date"
                  autoFocus
                  defaultValue={this.state.CaseInfo.c_field_visit_date}
                  onChange={this.UpdateFieldVisitDate}
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateFieldVisitDateModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadFieldVisitDate()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateTimeTransferValidityModal} onHide={this.UpdateTimeTransferValidityModal}>
          <Modal.Header>
            <Modal.Title>تحديث تاريخ الزيارةالميدانية</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="date"
                  autoFocus
                  defaultValue={this.state.CaseInfo.c_time_transfer_validity}
                  onChange={this.UpdateTimeTransferValidity}
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateTimeTransferValidityModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadTimeTransferValidity()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateDocumentCompletionDateModal} onHide={this.UpdateDocumentCompletionDateModal}>
          <Modal.Header>
            <Modal.Title>تحديث تاريخ استكمال الاوراق</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="date"
                  autoFocus
                  defaultValue={this.state.CaseInfo.c_document_completion_date}
                  onChange={this.UpdateDocumentCompletionDate}
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateDocumentCompletionDateModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadDocumentCompletionDate()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateStudyDeliveryDateModal} onHide={this.UpdateStudyDeliveryDateModal}>
          <Modal.Header>
            <Modal.Title>تحديث تاريخ تسليم الدراسة</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="date"
                  autoFocus
                  defaultValue={this.state.CaseInfo.c_study_delivery_date}
                  onChange={this.UpdateStudyDeliveryDate}
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateStudyDeliveryDateModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadStudyDeliveryDate()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.UpdateSortDateModal} onHide={this.UpdateSortDateModal}>
          <Modal.Header>
            <Modal.Title>تحديث تاريخ قرار الفرز</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>
                  {this.state.SortDate
                    ? Moment(this.state.SortDate).format("DD/MM/YYYY")
                    : Moment(this.state.CaseInfo.c_sort_date).format("DD/MM/YYYY")
                  }
                </Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={this.state.CaseInfo.c_sort_date}
                  onChange={this.UpdateSortDate}
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateSortDateModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadSortDate()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateCaseNotesModal} onHide={this.UpdateCaseNotesModal}>
          <Modal.Header>
            <Modal.Title>تحديث الملاحظات</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  as="textarea"
                  defaultValue={this.state.CaseInfo.c_notes}
                  rows={3}
                  onChange={this.UpdateCaseNotes}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateCaseNotesModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadCaseNotes()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateFileModal} onHide={this.UpdateFileModal}>
          <Modal.Header>
            <Modal.Title>اضافة ملف جديد</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type="file" onChange={this.convertToBase64} id="inputFile" />
                <div className="input-container">
                  <label>&zwnj;</label>
                  <select className="input" onChange={(e) => this.UpdateFileCategory(e.target.value)}>
                    <option disabled>اختار تصنيف الملف</option>
                    <option defaultValue value="المرفقات">المرفقات</option>
                    <option value="كتب التحويل">كتب التحويل</option>
                    <option value="فواتير المريض">فواتير المريض</option>
                    <option value="تجديد العلاج">تجديد العلاج</option>
                  </select>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateFileModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadFile()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showAlert} onHide={this.UpdateAlertWindow} backdrop="static">
          <Modal.Body>
            جاري تحديث الملف
          </Modal.Body>
        </Modal>


        <Modal show={this.state.UpdateExtraHospitalModal} onHide={this.UpdateExtraHospitalModal}>
          <Modal.Header>
            <Modal.Title>اضافة مستشفى</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label>&zwnj;</label>
                <select className="input" onClick={(e) => this.UpdateExtraHospital(e.target.value)}>
                  {this.state.hospitals.map((hospital, i) => {
                    return (
                      <option key={i} value={hospital.h_name} required>
                        {hospital.h_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateExtraHospitalModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadExtraHospital()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.UpdateExtraClassificationsModal} onHide={this.UpdateExtraClassificationsModal}>
          <Modal.Header>
            <Modal.Title>اضافة تصنيف</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label>&zwnj;</label>
                <select className="input" onClick={(e) => this.UpdateExtraClassification(e.target.value)}>
                  {this.state.Classifications.map((classification, i) => {
                    return (
                      <option key={i} value={classification.cl_name} required>
                        {classification.cl_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateExtraClassificationsModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadExtraClassification()
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withParams(CasesProfile);

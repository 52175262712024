import React from "react";
import Moment from "moment";
import UpdateUser from "./UpdateUser";
import UpdatePass from "./UpdatePass";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.GetUserInfo = this.GetUserInfo.bind(this);
  }

  async GetUserInfo() {
    this.props.GetUsers();
  }

  render() {
    return (
      <tr style={{ textAlign: "center" }}>
        <td>
          <p>
            <UpdatePass UserID={this.props.id}/>
          </p>
        </td>
        <td>
          <p>
            <UpdateUser
              UserID={this.props.id}
              UserName={this.props.name}
              Email={this.props.email}
              Position={this.props.position}
              GetUserInfo={this.GetUserInfo}
            />
          </p>
        </td>
        <td>
          <p>
            {this.props.UpdateDate === null
              ? "مجهول"
              : Moment(this.props.UpdateDate).format("DD/MM/YYYY")}
          </p>
        </td>

        <td>
          <p>
            {this.props.RegisterDate === null
              ? "مجهول"
              : Moment(this.props.RegisterDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>{this.props.position === null ? "مجهول" : this.props.position}</p>
        </td>
        <td>
          <p>{this.props.email === null ? "مجهول" : this.props.email}</p>
        </td>
        <td>
          <p>{this.props.name === null ? "مجهول" : this.props.name}</p>
        </td>
        <td>
          <p>{this.props.id === null ? "مجهول" : this.props.id}</p>
        </td>
      </tr>
    );
  }
}

export default User;

import React from "react";
import Moment from "moment";
import { ImProfile } from "react-icons/im"
import { FaCircle } from "react-icons/fa";

class Case extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.CalculateTime = this.CalculateTime.bind(this);
  }

  CalculateTime(dateString) {
    let date1 = new Date(Moment(this.props.CreateDate).format("MM/DD/YYYY"));
    let date2 = new Date(dateString);
    let time_difference = date2.getTime() - date1.getTime();
    let days_difference = Math.floor(time_difference / (1000 * 60 * 60 * 24));
    return days_difference
  }

  render() {
    const redirect = "/CaseProfile/" + this.props.CaseID;
    return (
      <tr style={{ textAlign: "center" }}>
        <td>
          <a style={{ color: "black" }} href={redirect}><ImProfile /></a>
        </td>
        <td>
          <p>{this.props.agent === null ? "مجهول" : this.props.agent}</p>
        </td>
        <td>
          <p>{this.props.state === null ? "مجهول" : this.props.state}</p>
        </td>
        <td>
          <p>{this.props.TimeTransferValidity === null ? "مجهول" : Moment(this.props.TimeTransferValidity).format("DD/MM/YYYY")}</p>
        </td>
        <td>
          <p>{this.props.TimeTransferValidity === null ? "مجهول" : this.CalculateTime(Moment(this.props.TimeTransferValidity).format("MM/DD/YYYY"))}</p>
        </td>
        <td>
          <p>
            {this.props.TransactionAmount === null
              ? "مجهول"
              : this.props.TransactionAmount}
          </p>
        </td>
        <td>
          <p>
            {this.props.TransactionDate === null
              ? "مجهول"
              : Moment(this.props.TransactionDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>{this.props.Hospital === null ? "مجهول" : this.props.Hospital}</p>
        </td>
        <td>
          <p>
            {this.props.Classification === null
              ? "مجهول"
              : this.props.Classification}
          </p>
        </td>
        <td>
          <p>
            {this.props.PatientBirthdayFrom === null
              ? "مجهول"
              : Moment(this.props.PatientBirthdayFrom).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>
            {this.props.PatientName === null ? "مجهول" : this.props.PatientName}
          </p>
        </td>
        <td>
          <p>{this.props.CaseNumber === null ? "مجهول" : this.props.CaseNumber}</p>
        </td>
        <td>
          <p><FaCircle color={this.props.CommentsCount > 0 ? "green" : "red"}/></p>
        </td>
      </tr>
    );
  }
}

export default Case;
import React from "react";
import Moment from "moment";
import {ImProfile} from "react-icons/im"
import {BsFillTrashFill} from "react-icons/bs"

class Case extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const redirect = "/CaseProfile/" + this.props.CaseID;
    return (
      <tr style={{ textAlign: "center" }}>
        <td>
          <BsFillTrashFill style={{color: "black", cursor: "pointer"}} onClick={() => this.props.RemoveCase(this.props.CaseID)}/>
        </td>
        <td>
          <a style={{color: "black"}} href={redirect}><ImProfile /></a>
        </td>
        <td>
          <p>{this.props.CreateDate === null ? "مجهول" : Moment(this.props.CaseCreateDate).format("DD/MM/YYYY")}</p>
        </td>
        <td>
          <p>
            {this.props.PatientName === null ? "مجهول" : this.props.PatientName}
          </p>
        </td>
        <td>
          <p>{this.props.CaseNumber === null ? "مجهول" : this.props.CaseNumber}</p>
        </td>
      </tr>
    );
  }
}

export default Case;

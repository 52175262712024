import React from "react";
import Moment from "moment";
import API from "../config/API";
import Form from "react-bootstrap/Form";
import { BsTrash } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OriginalComment: "",
      NewComment: "",
      UpdateCommentModal: false,
      showAlert: false
    };

    this.UpdateCommentModal = this.UpdateCommentModal.bind(this)
    this.UploadComment = this.UploadComment.bind(this)
    this.UpdateComment = this.UpdateComment.bind(this)
    this.DeleteComment = this.DeleteComment.bind(this);
    this.UpdateAlertWindow = this.UpdateAlertWindow.bind(this);
  }

  UpdateAlertWindow() {
    this.setState({ showAlert: !this.state.showAlert })
  }

  UpdateCommentModal() {
    this.setState({ UpdateCommentModal: !this.state.UpdateCommentModal })
  }

  async UploadComment() {
    const data = {
      NewComment: this.state.NewComment,
      CommentID: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/update_comment", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.UpdateCommentModal();
          this.props.getComments();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async DeleteComment() {
    const data = {
      CommentID: this.props.id,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateAlertWindow();

    await API.post("/remove_comment", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) {
          this.setState({ CaseComments: [] });
          this.props.getComments();
          this.UpdateAlertWindow()
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async UpdateComment(event) {
    await this.setState({ NewComment: event.target.value })
  }

  render() {
    return (
      <div className="Comment">
        <p>
          <span className="CommentAuthor">{this.props.Author}</span>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {this.props.Text}
          </pre>
        </p>
        {this.props.BelongToUser ? (
          <>
            <BsTrash
              style={{ marginRight: "20px", color: "red" }}
              onClick={() => {
                this.DeleteComment();
              }}
            />
            <BiEdit
              style={{ marginRight: "20px" }}
              onClick={() => {
                this.UpdateCommentModal();
              }}
            />
          </>
        ) : null}
        {Moment(this.props.CreateDate).format("DD/MM/YYYY")}
        <hr
          style={{ width: "30%", marginLeft: "auto", marginRight: 0 }}
        />

        <Modal show={this.state.UpdateCommentModal} onHide={this.UpdateCommentModal}>
          <Modal.Header>
            <Modal.Title>تعديل تعليق</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <textarea
                  autoFocus
                  onChange={this.UpdateComment}
                  defaultValue={this.props.Text}
                  style={{
                    textAlign: "right",
                    width: "100%",
                    height: "300px"
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateCommentModal}>
              اغلاق
            </button>
            <button
              className="button1"
              onClick={this.UploadComment}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showAlert} onHide={this.UpdateAlertWindow} backdrop="static">
          <Modal.Body>
            جاري حذف التعليق
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Comment;

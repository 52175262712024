import React from "react";
import Moment from "moment";
import {ImProfile} from "react-icons/im"
import { FaCircle } from "react-icons/fa";

class NewCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "/CaseProfile/" + this.props.CaseID,
    };
  }

  render() {
    return (
      <tr style={{ textAlign: "center" }}>
        <td>
          <a style={{color: "black"}} href={this.state.redirect}><ImProfile /></a>
        </td>
        <td>
          <p>{this.props.state === null ? "مجهول" : this.props.state}</p>
        </td>
        <td>
          <p>
            {this.props.StudyDeliveryDate === null
              ? "مجهول"
              : Moment(this.props.StudyDeliveryDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>
            {this.props.DocumentCompletionDate === null
              ? "مجهول"
              : Moment(this.props.DocumentCompletionDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>
            {this.props.FieldVisitDate === null
              ? "مجهول"
              : Moment(this.props.FieldVisitDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>{this.props.agent === null ? "مجهول" : this.props.agent}</p>
        </td>
        <td>
          <p>{this.props.FirstSortResult === null ? "مجهول" : this.props.FirstSortResult}</p>
        </td>
        <td>
          <p>
            {this.props.SortDate === null
              ? "مجهول"
              : Moment(this.props.SortDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>
            {this.props.PatientName === null ? "مجهول" : this.props.PatientName}
          </p>
        </td>
        <td>
          <p>{this.props.number === null ? "مجهول" : this.props.number}</p>
        </td>
        <td>
          <p><FaCircle color={this.props.CommentsCount > 0 ? "green" : "red"}/></p>
        </td>
      </tr>
    );
  }
}

export default NewCase;

import React from "react";
import API from "../config/API";
import Case from "../components/Case";
import { Modal } from "react-bootstrap";
import { AiFillFilter } from "react-icons/ai"
import { FaSort, FaCaretSquareRight, FaCaretSquareLeft } from "react-icons/fa"

class CasesDataBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [],
      agents: [],
      FilterActive: false,
      FilterModal: false,
      Sort: null,
      index: 0,
      filter: {
        FirstSortResultFilter: "",
        AgentFilter: "",
        PatientName: "",
        CaseNumber: "",
        CaseState: "",
        SortDateFrom: "",
        SortDateTo: "",
        StudyDeliveryDateFrom: "",
        StudyDeliveryDateTo: "",
        DocumentCompletionDateFrom: "",
        DocumentCompletionDateTo: "",
        FieldVisitDateFrom: "",
        FieldVisitDateTo: ""
      }
    };

    // Fetching
    this.GetCases = this.GetCases.bind(this);
    this.GetAgents = this.GetAgents.bind(this);

    // Updating
    this.UpdateStudyResultFilter = this.UpdateStudyResultFilter.bind(this);
    this.UpdateStateFilter = this.UpdateStateFilter.bind(this);
    this.UpdateClassificationFilter = this.UpdateClassificationFilter.bind(this);
    this.UpdateTransactionAmountFromFilter = this.UpdateTransactionAmountFromFilter.bind(this);
    this.UpdateTransactionAmountToFilter = this.UpdateTransactionAmountToFilter.bind(this);
    this.UpdateTransactionDateFromFilter = this.UpdateTransactionDateFromFilter.bind(this);
    this.UpdateTransactionDateToFilter = this.UpdateTransactionDateToFilter.bind(this);
    this.UpdateHospitalFilter = this.UpdateHospitalFilter.bind(this);
    this.UpdatePatientBirthdayFromFilter = this.UpdatePatientBirthdayFromFilter.bind(this);
    this.UpdatePatientBirthdayToFilter = this.UpdatePatientBirthdayToFilter.bind(this);
    this.UpdateAgentFilter = this.UpdateAgentFilter.bind(this);
    this.UpdatePatientNameFilter = this.UpdatePatientNameFilter.bind(this);
    this.UpdateCaseNumberFilter = this.UpdateCaseNumberFilter.bind(this);

    // Other
    this.Filter = this.Filter.bind(this);
    this.FilterModal = this.FilterModal.bind(this);
    this.Reset = this.Reset.bind(this);
    this.SortCases = this.SortCases.bind(this);
    this.increaseIndex = this.increaseIndex.bind(this);
    this.decreaseIndex = this.decreaseIndex.bind(this);
  }

  async increaseIndex() {
    await this.setState({ index: this.state.index + 20 });
    this.GetCases();
  }

  async decreaseIndex() {
    await this.setState({ index: this.state.index- 20 });
    this.GetCases();
  }

  async GetAgents() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_agents", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ agents: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async SortCases() {
    if (this.state.Sort === "ASC") {
      const sortedCases = this.state.cases.sort((a, b) => b.c_number - a.c_number);
      this.setState({ cases: sortedCases, Sort: "DESC" });
    }
    
    if (this.state.Sort === "DESC"){
      const sortedCases = this.state.cases.sort((a, b) => b.c_number + a.c_number);
      this.setState({ cases: sortedCases, Sort: "ASC" });
    }
  }

  async Reset() {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        FirstSortResultFilter: "",
        AgentFilter: "",
        PatientName: "",
        CaseNumber: "",
        CaseState: "",
        SortDateFrom: "",
        SortDateTo: "",
        StudyDeliveryDateFrom: "",
        StudyDeliveryDateTo: "",
        DocumentCompletionDateFrom: "",
        DocumentCompletionDateTo: "",
        FieldVisitDateFrom: "",
        FieldVisitDateTo: ""
      },
      FilterActive: false
    }));
    this.Filter();
  }

  async UpdateTransactionAmountToFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        TransactionAmountTo: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdateTransactionDateToFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        TransactionDateTo: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdatePatientBirthdayToFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        PatientBirthdayTo: event.target.value,
      },
      FilterActive: true
    }));
  }

  FilterModal() {
    this.setState({ FilterModal: !this.state.FilterModal });
  }

  /* eslint-disable eqeqeq */
  async Filter() {
    this.GetCases();
  }

  async UpdateStudyResultFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        StudyResultFilter: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdateStateFilter(value) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        StateFilter: value,
      },
      FilterActive: true
    }));
  }

  async UpdateClassificationFilter(value) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        Classification: value,
      },
      FilterActive: true
    }));
  }

  async UpdateTransactionAmountFromFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        TransactionAmountFrom: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdateTransactionDateFromFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        TransactionDateFrom: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdateHospitalFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        Hospital: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdatePatientBirthdayFromFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        PatientBirthdayFrom: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdateAgentFilter(value) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        AgentFilter: value,
      },
      FilterActive: true
    }));
  }

  async UpdatePatientNameFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        PatientName: event.target.value,
      },
      FilterActive: true
    }));
  }

  async UpdateCaseNumberFilter(event) {
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        CaseNumber: event.target.value,
      },
      FilterActive: true
    }));
  }

  async GetCases() {
    const data = {
      Token: window.localStorage.getItem("token"),
      index: this.state.index,
      filter: this.state.filter
    };

    await API.post("/get_rejected_cases", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          window.alert(res.ErrorMessage);
        }
        if (res.data) {
          const sortedCases = res.data.sort((a, b) => b.c_number - a.c_number);
          this.setState({ cases: sortedCases, Sort: "DESC" });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidMount() {
    this.GetCases();
    this.GetAgents();
  }

  render() {
    return (
      <div className="CenterPage" style={{ width: "90%" }}>
        <div className="insideCard">
          <div className="PageContainer">
            <table className="TableContainer">
              <thead style={{ borderBottom: "1px solid rgb(201, 201, 201)" }}>
                <tr className="TableHeader">
                  <th>تفاصيل الملف</th>
                  <th>الباحثة المكلفة</th>
                  <th>حالة الملف</th>
                  <th>صلاحية التحويل الزمني</th>
                  <th>نهاية التحويل الزمني</th>
                  <th>سقف التحويل</th>
                  <th>تاريخ التحويل</th>
                  <th>المستشفى</th>
                  <th>التصنيف</th>
                  <th>تاريخ ميلاد المريض</th>
                  <th>اسم المريض</th>
                  <th>رقم الملف</th>
                  <th>
                    <AiFillFilter size={20} onClick={this.FilterModal} />
                    <FaSort size={20} onClick={this.SortCases} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.cases.map((element, i) => {
                  return (
                    <Case
                      key={i}
                      CaseID={element.c_id}
                      CaseNumber={element.c_number}
                      PatientName={element.c_patient_name}
                      PatientBirthdayFrom={element.c_patient_birthday}
                      Classification={element.c_classification}
                      Hospital={element.c_hospital}
                      TransactionDate={element.c_transaction_date}
                      TransactionAmount={element.c_transaction_amount}
                      TimeTransferValidity={element.c_time_transfer_validity}
                      agent={element.c_agent}
                      notes={element.c_notes}
                      state={element.c_state}
                      CreateDate={element.c_create_date}
                      CommentsCount={element.comments_count}
                    />
                  );
                })}
              </tbody>
            </table>

            <Modal show={this.state.FilterModal} onHide={this.FilterModal}>
              <Modal.Header>
                <Modal.Title>تصفية النتائج</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div className="input-container" style={{ paddingRight: "10px" }}>
                  <label for="title-input">رقم الحالة</label>
                  <input
                    type="text"
                    id="title-input"
                    name="title-input"
                    className="input"
                    onChange={this.UpdateCaseNumberFilter}
                    placeholder="التصفية حسب رقم الملف"
                    value={this.state.filter.CaseNumber}
                    style={{
                      fontSize: "13px",
                      padding: " 7px",
                      width: "300px"
                    }}
                  />
                </div>

                <div className="input-container" style={{ paddingRight: "10px" }}>
                  <label for="title-input">الاسم</label>
                  <input
                    type="text"
                    id="title-input"
                    name="title-input"
                    className="input"
                    placeholder="التصفية حسب اسم المريض "
                    value={this.state.filter.PatientName}
                    onChange={this.UpdatePatientNameFilter}
                    style={{
                      fontSize: "13px",
                      padding: " 7px",
                      width: "300px",
                      textAlign: "right"
                    }}
                  />
                </div>

                {/* <div className="input-group-row">
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>&zwnj;</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateSortDateFromFilter}
                      value={this.state.filter.SortDateFrom}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "-5px",
                      }}
                    />
                  </div>
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>تاريخ قرار الفرز</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateSortDateToFilter}
                      value={this.state.filter.SortDateTo}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div> */}

                {/* <div
                  className="input-group-row"
                  style={{ marginTop: "-10px" }}
                >
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label style={{ marginTop: "10px" }}>نتيجة الفرز الاولي</label>
                    <select
                      className="input"
                      onChange={(e) => this.UpdateFirstSortResultFilter(e.target.value)}
                      value={this.state.filter.FirstSortResultFilter}
                    >
                      <option value="الغاء">الغاء</option>
                      <option value="دراسة">دراسة</option>
                      <option value="عدم دراسة">عدم دراسة</option>
                    </select>
                  </div>
                </div> */}

                <div
                  className="input-group-row"
                  style={{ marginTop: "-10px" }}
                >
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label style={{ marginTop: "10px" }}>الباحثة المكلفه</label>
                    <select className="input" onChange={(e) => this.UpdateAgentFilter(e.target.value)} value={this.state.filter.AgentFilter}>
                      <option value="الغاء">الغاء</option>
                      {this.state.agents.map((agent, i) => {
                        return (
                          <option key={i} value={agent.ag_name}>{agent.ag_name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
{/* 
                <div className="input-group-row">
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>&zwnj;</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateFieldVisitDateFromFilter}
                      value={this.state.filter.FieldVisitDateFrom}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "-5px",
                      }}
                    />
                  </div>
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>تاريخ الزيارة الميدانية</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateFieldVisitDateToFilter}
                      value={this.state.filter.FieldVisitDateTo}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>

                <div className="input-group-row">
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>&zwnj;</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateDocumentCompletionDateFromFilter}
                      value={this.state.filter.DocumentCompletionDateFrom}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "-5px",
                      }}
                    />
                  </div>
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>تاريخ استكمال الاوراق</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateDocumentCompletionDateToFilter}
                      value={this.state.filter.DocumentCompletionDateTo}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>

                <div className="input-group-row">
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>&zwnj;</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateStudyDeliveryDateFromFilter}
                      value={this.state.filter.StudyDeliveryDateFrom}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "-5px",
                      }}
                    />
                  </div>
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label>تاريخ تسلم الدراسة</label>
                    <input
                      type="date"
                      name="title-input"
                      onChange={this.UpdateStudyDeliveryDateToFilter}
                      value={this.state.filter.StudyDeliveryDateTo}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "150px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>

                <div className="input-group-row" style={{ marginTop: "-10px" }}>
                  <div className="input-container" style={{ paddingRight: "10px" }}>
                    <label style={{ marginTop: "10px" }}>حالة الملف</label>
                    <select className="input" onChange={(e) => this.UpdateCaseStateFilter(e.target.value)} >
                      // {this.state.filter.CaseState === "" ?
                      //   (<option disabled selected>لا يوجد</option>)
                      //   :
                      //   ""
                      // }
                      <option value="">الغاء</option>
                      <option value="محول">محول</option>
                      <option value="غير محول">غير محول</option>
                    </select>
                  </div>
                </div> */}

              </Modal.Body>
              <Modal.Footer>
                <button className="button1" onClick={this.FilterModal}>
                  اغلاق
                </button>
                <button className="button1" onClick={this.Filter}>
                  بحث
                </button>
                <button
                  className="button2"
                  onClick={this.Reset}
                >
                  اعادة ضبط الفلاتر
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div style={{ textAlign: "center"}}>
          {this.state.index > 0 ? <FaCaretSquareLeft onClick={this.decreaseIndex}/> : null}
          {this.state.cases.length < 20 ? null : <FaCaretSquareRight onClick={this.increaseIndex}/>}
        </div>
      </div>
    );
  }
}

export default CasesDataBase;

import React from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { BiEdit } from "react-icons/bi";
import API from "../config/API";

class UpdateAgent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NewAgentName: "",
      UpdateAgentModal: false,
    };

    this.UpdateAgentName = this.UpdateAgentName.bind(this);
    this.UploadAgentInfo = this.UploadAgentInfo.bind(this);
    this.UpdateAgentModal = this.UpdateAgentModal.bind(this);
    this.SetDefaultVlaues = this.SetDefaultVlaues.bind(this);
  }

  SetDefaultVlaues() {
    this.setState({ NewAgentName: this.props.AgentName });
  }

  UpdateAgentModal() {
    this.setState({ UpdateAgentModal: !this.state.UpdateAgentModal });
  }

  UpdateAgentName(event) {
    this.setState({ NewAgentName: event.target.value });
  }

  async UploadAgentInfo() {
    const data = {
      Token: window.localStorage.getItem("token"),
      AgentID: this.props.AgentID,
      NewAgentName: this.state.NewAgentName,
    };

    await API.post("/update_agent_info", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.props.GetAgentInfo();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidMount() {
    this.SetDefaultVlaues();
  }

  render() {
    return (
      <>
        <BiEdit className="EditButton" onClick={this.UpdateAgentModal} />
        <Modal show={this.state.UpdateAgentModal} onHide={this.UpdateAgentModal}>
          <Modal.Header closeButton>
            <Modal.Title>تحديث بيانات المستخدم</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ textAlign: "right" }}>
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>الاسم</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={this.props.AgentName}
                  style={{ textAlign: "right" }}
                  onChange={this.UpdateAgentName}
                />
              </Form.Group> */}
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input"> الاسم</label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  defaultValue={this.props.AgentName}
                  onChange={this.UpdateAgentName}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={this.UpdateAgentModal}>
              اغلاق
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.UploadAgentInfo();
                this.UpdateAgentModal();
              }}
            >
              حفظ
            </Button> */}
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateAgentModal}>
            إغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadAgentInfo();
                this.UpdateAgentModal();
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UpdateAgent;

import React from "react";
import Form from "react-bootstrap/Form";
import API from "../config/API";
import { Modal } from "react-bootstrap";

class DataEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      StudyDeliveryDate: null,
      SortDate: null,
      PatientName: null,
      PatientBirthday: null,
      file: null,
      CaseFileName: null,
      FirstPhoneNumber: null,
      SecondPhoneNumber: null,
      FieldVisitDate: null,
      NotStudyReason: null,
      showAlert: false,
      S3SignedURL: "",
      FirstSortResult: "دراسة",
      agent: "اختار باحث/ة",
      agents: [],
    };

    // Updating
    this.UpdateCaseFile = this.UpdateCaseFile.bind(this);
    this.UpdateFileName = this.UpdateFileName.bind(this);
    this.UpdatePatientName = this.UpdatePatientName.bind(this);
    this.UpdatePatientBirthday = this.UpdatePatientBirthday.bind(this);
    this.UpdateAgent = this.UpdateAgent.bind(this);
    this.UpdateFieldVisitDate = this.UpdateFieldVisitDate.bind(this);
    this.UpdateStudyDeliveryDate = this.UpdateStudyDeliveryDate.bind(this);
    this.UpdateSortDate = this.UpdateSortDate.bind(this);
    this.UpdateFirstSortResult = this.UpdateFirstSortResult.bind(this);
    this.UpdateFirstPhoneNumber = this.UpdateFirstPhoneNumber.bind(this);
    this.UpdateSecondPhoneNumber = this.UpdateSecondPhoneNumber.bind(this);
    this.UpdateNotStudyReason = this.UpdateNotStudyReason.bind(this);

    // File processing
    this.convertToBase64 = this.convertToBase64.bind(this);

    // Uploading
    this.UploadNewCase = this.UploadNewCase.bind(this);
    this.UploadNewCaseFile = this.UploadNewCaseFile.bind(this);
    this.UpdateAlertWindow = this.UpdateAlertWindow.bind(this);

    // Fetching
    this.GetAgents = this.GetAgents.bind(this);
  }

  async UploadNewCaseFile(S3SignedURL) {
    try {
      const url = S3SignedURL;
      const casesIndex = url.indexOf('Cases/') + 'Cases/'.length;
      const caseID = url.substring(casesIndex, url.indexOf('?', casesIndex)).split('/')[0];
      const CaseFile = this.state.file;
      const sendToS3 = await fetch(S3SignedURL, {
        method: 'PUT',
        body: CaseFile,
      });

      if (sendToS3.ok) {
        const data = {
          CaseID: caseID,
          CaseFileName: this.state.CaseFileName,
          CaseFileCategory: "المرفقات",
          Token: window.localStorage.getItem("token"),
        };
        const response = await API.post("/add_new_file", data);
        const res = response.data;
        if (res.ErrorMessage) {
          window.alert(res.ErrorMessage);
          return false
        }
        if (res.data) {
          return true;
        }
      } else {
        console.error('Error uploading file:', sendToS3.statusText);
        return false;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return false;
    }
  }

  UpdateAlertWindow() {
    this.setState({ showAlert: !this.state.showAlert })
  }

  async GetAgents() {
    const data = { Token: window.localStorage.getItem("token") };

    await API.post("/get_agents", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.setState({ agents: res.data });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  UpdateFirstPhoneNumber(event) {
    this.setState({ FirstPhoneNumber: event.target.value });
  }

  UpdateSecondPhoneNumber(event) {
    this.setState({ SecondPhoneNumber: event.target.value });
  }

  UpdateNotStudyReason(event) {
    this.setState({ NotStudyReason: event.target.value });
  }

  UpdateFirstSortResult(value) {
    this.setState({ FirstSortResult: value });
  }

  convertToBase64(event) {
    const selectedFile = document.getElementById("inputFile").files;

    if (selectedFile.length > 0) {
      const fileToLoad = selectedFile[0];
      const fileReader = new FileReader();
      let self = this;

      fileReader.onload = async function (fileLoadedEvent) {
        let base64 = fileLoadedEvent.target.result;
        let fileName = event.target.files[0].name;
        self.UpdateCaseFile(base64);
        self.UpdateFileName(fileName);
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  UpdateSortDate(event) {
    this.setState({ SortDate: event.target.value });
  }

  UpdatePatientName(event) {
    this.setState({ PatientName: event.target.value });
  }

  UpdatePatientBirthday(event) {
    this.setState({ PatientBirthday: event.target.value });
  }

  UpdateAgent(value) {
    this.setState({ agent: value });
  }

  UpdateFieldVisitDate(event) {
    this.setState({ FieldVisitDate: event.target.value });
  }

  UpdateStudyDeliveryDate(event) {
    this.setState({ StudyDeliveryDate: event.target.value });
  }

  UpdateCaseFile(base64) {
    this.setState({ file: base64 });
  }

  UpdateFileName(FileName) {
    this.setState({ CaseFileName: FileName });
  }

  async UploadNewCase(e) {
    e.preventDefault();
    const data = {
      CaseFileName: this.state.CaseFileName,
      PatientName: this.state.PatientName,
      PatientBirthday: this.state.PatientBirthday,
      Agent: this.state.agent,
      FieldVisitDate: this.state.FieldVisitDate,
      StudyDeliveryDate: this.state.StudyDeliveryDate,
      SortDate: this.state.SortDate,
      FirstSortResult: this.state.FirstSortResult,
      FirstPhoneNumber: this.state.FirstPhoneNumber,
      SecondPhoneNumber: this.state.SecondPhoneNumber,
      NotStudyReason: this.state.NotStudyReason,
      Token: window.localStorage.getItem("token"),
    };

    this.UpdateAlertWindow();

    await API.post("/add_case", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          this.UpdateAlertWindow();
          window.alert(res.ErrorMessage);
        }
        if (res.data) {
          if (this.state.file) {
            (async () => {
              const uplaodtos3 = await this.UploadNewCaseFile(res.data);
              if (uplaodtos3) {
                this.UpdateAlertWindow();
                window.location = "/NewCases";
              }
            })();
          } else {
            window.location = "/NewCases";
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidMount() {
    this.GetAgents();
  }

  render() {
    return (
      <div className="CenterPage">
        <div className="insideCard" style={{ position: "relative", paddingBottom: "30px" }}>
          <Form>
            <div className="input-container">
              <label for="title-input">اسم المريض</label>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdatePatientName}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "300px",
                  textAlign: "right"
                }}
              />
            </div>

            <div className="input-container">
              <label for="title-input">تاريخ ميلاد المريض</label>
              <input
                type="date"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdatePatientBirthday}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "auto"
                }}
              />
            </div>

            <div className="input-container">
              <label for="title-input">رقم الهاتف الاول</label>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdateFirstPhoneNumber}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "350px",
                }}
              />
            </div>

            <div className="input-container">
              <label for="title-input">رقم الهاتف الثاني</label>
              <input
                type="text"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdateSecondPhoneNumber}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "350px",
                }}
              />
            </div>

            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label style={{ marginTop: "10px" }}>قرار الفرز الأولي</label>
                <select className="input" onChange={(e) => this.UpdateFirstSortResult(e.target.value)}>
                  <option value="دراسة">دراسة</option>
                  <option value="عدم دراسة">عدم دراسة</option>
                </select>
              </div>
            </div>

            {(() => {
              if (this.state.FirstSortResult === "عدم دراسة") {
                return (
                  <div className="input-container">
                    <label for="title-input">سبب عدم الدراسة</label>
                    <textarea
                      type="text"
                      id="title-input"
                      name="title-input"
                      className="input"
                      onChange={this.UpdateNotStudyReason}
                      style={{
                        fontSize: "13px",
                        padding: " 7px",
                        width: "auto",
                        textAlign: "right"
                      }}
                    />
                  </div>
                )
              }
            })()}

            <div className="input-container">
              <label for="title-input">تاريخ قرار الفرز</label>
              <input
                type="date"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdateSortDate}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "auto",
                }}
              />
            </div>

            <div
              className="input-group-row"
              style={{ marginTop: "-10px" }}
            >
              <div className="input-container">
                <label style={{ marginTop: "10px" }}>اسم الباحثة المكلفة</label>
                <select className="input" onClick={(e) => this.UpdateAgent(e.target.value)} disabled={this.state.FirstSortResult === "دراسة" ? false : true}>
                  <option value="اختار باحثة">اختار باحثة</option>
                  {this.state.agents.map((agent, i) => {
                    return (
                      <option key={i} value={agent.ag_name}>
                        {agent.ag_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="input-container">
              <label for="title-input">تاريخ الزيارة الميدانية</label>
              <input
                type="date"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdateFieldVisitDate}
                disabled={this.state.FirstSortResult === "دراسة" ? false : true}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "auto",
                }}
              />
            </div>

            <div className="input-container">
              <label for="title-input">تاريخ تسليم الدراسة</label>
              <input
                type="date"
                id="title-input"
                name="title-input"
                className="input"
                onChange={this.UpdateStudyDeliveryDate}
                disabled={this.state.FirstSortResult === "دراسة" ? false : true}
                // required={this.state.FirstSortResult === "دراسة" ? false : true}
                style={{
                  fontSize: "13px",
                  padding: " 7px",
                  width: "auto",
                }}
              />
            </div>

            <Form.Group className="mb-3" controlId="formBasicPassword" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: "20px" }}>
              <Form.Label style={{ float: "right" }}>مرفقات الملف</Form.Label>
              <Form.Control id="inputFile" type="file" style={{ textAlign: "right", width: "auto", color: "rgb(90, 90, 90)" }} onChange={this.convertToBase64} />
            </Form.Group>
            <hr style={{ color: "rgb(180, 180, 180)" }} />
            <button style={{ width: "100px", borderRadius: 5, backgroundColor: "rgb(0, 107, 214)", color: "white", padding: 5 }} onClick={this.UploadNewCase} className="sendButton sendButtonEntry">ارسال</button>
          </Form>
        </div>

        <Modal show={this.state.showAlert} onHide={this.UpdateAlertWindow} backdrop="static">
          <Modal.Body>
            جاري ارسال الملف
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DataEntry;

import React from "react";
import Moment from "moment";
import UpdateAgent from "./UpdateAgent"

class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.GetAgentInfo = this.GetAgentInfo.bind(this);
  }

  async GetAgentInfo() {
    this.props.GetAgents();
  }

  render() {
    return (
      <tr style={{ textAlign: "center" }}>
        <td>
          <p>
            <UpdateAgent
              AgentID={this.props.id}
              AgentName={this.props.name}
              GetAgentInfo={this.GetAgentInfo}
            />
          </p>
        </td>

        <td>
          <p>
            {this.props.UpdateDate === null
              ? "مجهول"
              : Moment(this.props.UpdateDate).format("DD/MM/YYYY")}
          </p>
        </td>

        <td>
          <p>
            {this.props.RegisterDate === null
              ? "مجهول"
              : Moment(this.props.RegisterDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>{this.props.name === null ? "مجهول" : this.props.name}</p>
        </td>
        <td>
          <p>{this.props.id === null ? "مجهول" : this.props.id}</p>
        </td>
      </tr>
    );
  }
}

export default Agent;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import AdminPanel from "../pages/AdminPanel";
import PrivateRoute from "./PrivateRoute";
import DataEntry from "../pages/DataEntry";
import CasesDataBase from "../pages/CasesDataBase";
import NewCases from "../pages/NewCases";
import BindedCases from "../pages/BindedCases";
import ReadyToTransfareCases from "../pages/ReadyToTransfareCases";
import RejectedCases from "../pages/RejectedCases";
import TransfaredCases from "../pages/TransfaredCases";
import NotTostduyCases from "../pages/NotToStudyCases"
import CaseProfile from "../pages/CaseProfile";
import PrivateNavBar from "./PrivateNavBar";
import Navbar from "../components/NavBar";
import TransfaredCasesExternalNetwork from "../pages/TransfaredCases-ExternalNetwork";

class Routing extends React.Component {
  render() {
    return (
      <>
        <Router>
          <PrivateNavBar>
            <Navbar />
          </PrivateNavBar>
          <Routes>
            <Route exact path="/Login" element={<Login />} />
            <Route
              exact
              path="/"
              element={<PrivateRoute>{null}</PrivateRoute>}
            />
            <Route
              exact
              path="/DataEntry"
              element={
                <PrivateRoute>
                  <DataEntry />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/CasesDatabase"
              element={
                <PrivateRoute>
                  <CasesDataBase />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/NotTostduyCases"
              element={
                <PrivateRoute>
                  <NotTostduyCases />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/NewCases"
              element={
                <PrivateRoute>
                  <NewCases />
                </PrivateRoute>
              }
            />
           <Route
              exact
              path="/BindingCases"
              element={
                <PrivateRoute>
                  <BindedCases/>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/ReadyToTransfareCases"
              element={
                <PrivateRoute>
                  <ReadyToTransfareCases />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/TransfaredCases"
              element={
                <PrivateRoute>
                  <TransfaredCases />
                </PrivateRoute>
              }
            />
           <Route
              exact
              path="/TransfaredCasesExternalNetwork"
              element={
                <PrivateRoute>
                  <TransfaredCasesExternalNetwork />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/RejectedCases"
              element={
                <PrivateRoute>
                  <RejectedCases />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/AdminPanel"
              element={
                <PrivateRoute>
                  <AdminPanel />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/CaseProfile/:id"
              element={
                <PrivateRoute>
                  <CaseProfile />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </>
    );
  }
}

export default Routing;

import React from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { BiEdit } from "react-icons/bi";
import API from "../config/API";

class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NewUserName: "",
      NewUserEmail: "",
      NewUserPosition: "",
      NewUserPassword: "",
      UpdateUserModal: false,
    };

    this.UpdateUserName = this.UpdateUserName.bind(this);
    this.UpdateUserEmail = this.UpdateUserEmail.bind(this);
    this.UpdateUserPosition = this.UpdateUserPosition.bind(this);
    this.UpdateUserPassword = this.UpdateUserPassword.bind(this);
    this.UploadUserInfo = this.UploadUserInfo.bind(this);
    this.UpdateUserModal = this.UpdateUserModal.bind(this);
    this.SetDefaultVlaues = this.SetDefaultVlaues.bind(this);
  }

  SetDefaultVlaues() {
    this.setState({ NewUserName: this.props.UserName });
    this.setState({ NewUserEmail: this.props.Email });
    this.setState({ NewUserPosition: this.props.Position });
  }

  UpdateUserModal() {
    this.setState({ UpdateUserModal: !this.state.UpdateUserModal });
  }

  UpdateUserName(event) {
    this.setState({ NewUserName: event.target.value });
  }

  UpdateUserEmail(event) {
    this.setState({ NewUserEmail: event.target.value });
  }

  UpdateUserPosition(value) {
    this.setState({ NewUserPosition: value });
  }

  UpdateUserPassword(event) {
    this.setState({ NewUserPassword: event.target.value });
  }

  async UploadUserInfo() {
    const data = {
      Token: window.localStorage.getItem("token"),
      UserID: this.props.UserID,
      UserEmail: this.state.NewUserEmail,
      UserName: this.state.NewUserName,
      UserPosition: this.state.NewUserPosition,
    };

    await API.post("/update_user_info", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          window.alert(res.ErrorMessage);
        }
        if (res.data) {
          this.props.GetUserInfo();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidMount() {
    this.SetDefaultVlaues();
  }

  render() {
    return (
      <>
        <BiEdit className="EditButton" onClick={this.UpdateUserModal} />
        <Modal show={this.state.UpdateUserModal} onHide={this.UpdateUserModal}>
          <Modal.Header closeButton>
            <Modal.Title>تحديث بيانات المستخدم</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ textAlign: "right" }}>
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input">الاسم </label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  defaultValue={this.props.UserName}
                  onChange={this.UpdateUserName}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input"> الايميل</label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  defaultValue={this.props.Email}
                  onChange={this.UpdateUserEmail}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
              <div
                className="input-group-row"
                style={{ marginTop: "-10px" }}
              >
                <div className="input-container" style={{ paddingRight: "10px" }}>
                  <label style={{ marginTop: "10px" }}> الوظيفة</label>
                  <select className="input" onChange={(e) => this.UpdateUserPosition(e.target.value)}>
                    <option value="ضابط الارتباط" selected={this.state.NewUserPosition === "ضابط الارتباط" ? true : false}>ضابط الارتباط</option>
                    <option value="فريق التحويل" selected={this.state.NewUserPosition === "فريق التحويل" ? true : false}>فريق التحويل</option>
                    <option value="مسؤول التحويل" selected={this.state.NewUserPosition === "مسؤول التحويل" ? true : false}>مسؤول التحويل</option>
                  </select>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateUserModal}>
              إغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadUserInfo();
                this.UpdateUserModal();
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UpdateUser;

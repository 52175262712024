import React from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { BiEdit } from "react-icons/bi";
import API from "../config/API";

class UpdateHospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NewHospitalName: "",
      UpdateHospitalModal: false,
    };

    this.UpdateHospitalName = this.UpdateHospitalName.bind(this);
    this.UploadHospitalInfo = this.UploadHospitalInfo.bind(this);
    this.UpdateHospitalModal = this.UpdateHospitalModal.bind(this);
    this.SetDefaultVlaues = this.SetDefaultVlaues.bind(this);
  }

  SetDefaultVlaues() {
    this.setState({ NewHospitalName: this.props.HospitalName });
  }

  UpdateHospitalModal() {
    this.setState({ UpdateHospitalModal: !this.state.UpdateHospitalModal });
  }

  UpdateHospitalName(event) {
    this.setState({ NewHospitalName: event.target.value });
  }

  async UploadHospitalInfo() {
    const data = {
      Token: window.localStorage.getItem("token"),
      HospitalID: this.props.HospitalID,
      NewHospitalName: this.state.NewHospitalName,
    };

    await API.post("/update_hospital_info", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) window.alert(res.ErrorMessage);
        if (res.data) this.props.GetHospitalInfo();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidMount() {
    this.SetDefaultVlaues();
  }

  render() {
    return (
      <>
        <BiEdit className="EditButton" onClick={this.UpdateHospitalModal} />
        <Modal show={this.state.UpdateHospitalModal} onHide={this.UpdateHospitalModal}>
          <Modal.Header closeButton>
            <Modal.Title>تحديث بيانات المستشفى</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ textAlign: "right" }}>
              <div className="input-container" style={{ paddingRight: "10px" }}>
                <label for="title-input"> الاسم</label>
                <input
                  type="text"
                  id="title-input"
                  name="title-input"
                  className="input"
                  defaultValue={this.props.HospitalName}
                  onChange={this.UpdateHospitalName}
                  style={{
                    fontSize: "13px",
                    padding: " 7px",
                    width: "300px"
                  }}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="button2" style={{ width: "auto" }} onClick={this.UpdateHospitalModal}>
            إغلاق
            </button>
            <button
              className="button1"
              onClick={() => {
                this.UploadHospitalInfo();
                this.UpdateHospitalModal();
              }}
            >
              حفظ
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UpdateHospital;

import React from "react";
import Moment from "moment";
import {BsFillTrashFill} from "react-icons/bs"

class Classification extends React.Component {
  render() {
    return (
      <tr style={{ textAlign: "center" }}>
        <td>
          <BsFillTrashFill style={{color: "black", cursor: "pointer"}} onClick={() => this.props.RemoveClassification(this.props.id)}/>
        </td>
        <td>
          <p>
            {this.props.RegisterDate === null
              ? "مجهول"
              : Moment(this.props.RegisterDate).format("DD/MM/YYYY")}
          </p>
        </td>
        <td>
          <p>{this.props.name === null ? "مجهول" : this.props.name}</p>
        </td>
        <td>
          <p>{this.props.id === null ? "مجهول" : this.props.id}</p>
        </td>
      </tr>
    );
  }
}

export default Classification;

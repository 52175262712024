import React from "react";
import API from "../config/API";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
    };

    this.Login = this.Login.bind(this);

    // Updating
    this.UpdateEmail = this.UpdateEmail.bind(this);
    this.UpdatePassword = this.UpdatePassword.bind(this);
  }

  UpdateEmail(event) {
    this.setState({ error: "" });
    this.setState({ email: event.target.value });
  }

  UpdatePassword(event) {
    this.setState({ error: "" });
    this.setState({ password: event.target.value });
  }

  async Login() {
    const data = {
      UserEmail: this.state.email,
      UserPassword: this.state.password,
    };

    this.setState({ error: "جاري تسجيل الدخول" });

    await API.post("/login", data).then((response) => {
      const res = response.data;

      if (res.ErrorMessage) {
        this.setState({ error: res.ErrorMessage });
      }
      if (res.data) {
        window.localStorage.setItem("token", res.data);
        window.location = "/NewCases";
      }
    });
  }

  render() {
    return (
      <div className="Login">
        <form action="index.html" method="post">
          <h1>
            <span
              style={{
                color: "#324ab2",
                fontWeight: "bold",
                fontSize: "2.5rem",
              }}
            >
              شفاء
            </span>
          </h1>
          <p className="ErrorMessage">{this.state.error}</p>
          <div>
            <input
              id="user-name"
              name="user-name"
              placeholder="الايميل"
              type="text"
              onChange={this.UpdateEmail}
            />
            <input
              id="password"
              name="password"
              placeholder="كلمة السر"
              type="password"
              onChange={this.UpdatePassword}
            />
            <br />
            <div className="LoginButton" onClick={this.Login}>
              تسجيل الدخول
            </div>
            <br />
          </div>
        </form>
      </div>
    );
  }
}

export default Login;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import API from "../config/API";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      UserName: "",
    };

    this.logout = this.logout.bind(this);
  }

  logout() {
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  }

  async GetUserName() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_user_name", data).then((response) => {
      const res = response.data;

      if (res.ErrorMessage) window.alert(res.ErrorMessage);
      if (res.data) this.setState({ UserName: res.data });
    });
  }

  componentDidMount() {
    this.GetUserName();
  }

  render() {
    return (
      <ul className="Navbar">
        <li>
          <NavLink className="UserName" to={() => false}>
            {this.state.UserName}
          </NavLink>
        </li>
        <li>
          <NavLink className="link" to="/DataEntry">
            إدخال ملف جديد
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/NewCases">
            الملفات الجديدة
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/ReadyToTransfareCases">
            الملفات الجاهزة للتحويل
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/TransfaredCases">
            الملفات المحولة
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/TransfaredCasesExternalNetwork">
            الملفات المحولة (الشبكة الخارجية)
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/BindingCases">
            الملفات العالقة
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/RejectedCases">
            الملفات المرفوضة
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/CasesDatabase">
            قاعدة بيانات المرضى
          </NavLink>
        </li>

        <li>
          <NavLink className="link" to="/NotTostduyCases">
            الملفات المرفوض دراستها
          </NavLink>
        </li>

        {/* {this.state.authorized ? (
          <li>
            <NavLink className="link" to="/AdminPanel">
              لوحة الادمن
            </NavLink>
          </li>
        ) : null} */}
        
        <li>
            <NavLink className="link" to="/AdminPanel">
              لوحة الادمن
            </NavLink>
          </li>

        <li style={{ float: "left" }}>
          <NavLink
            className="link"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.0)", boxShadow: "none" }}
            to={() => false}
            onClick={this.logout}
          >
            <i class="fas fa-arrow-alt-circle-left" id=""></i>
            خروج
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default Navbar;
